import { fromEvent, debounceTime, firstValue, scrollToElem } from '@/utils'

export default function fixAnchorPos(event) {
  const id = event.target.getAttribute('href').replace('#', '')
  const target = document.getElementById(id)
  fromEvent(window, 'scroll')
    .pipe(debounceTime(50))
    .pipe(firstValue())
    .subscribe(() => scrollToElem(target))
}
