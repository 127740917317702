<template>
  <div class="range-input" :class="classname">
    <div ref="labelBar" class="range-input__label-bar">
      <div v-for="(label, i) in labels"
           v-text="label.text"
           class="range-input__label"
           :style="{ flexBasis: toPercent(labelFlexBasis) }"
           :key="label.value"
           @click="setLabel(i)" />
    </div>
    <div ref="bar"
         class="range-input__bar"
         @click="handleBarTouchClick">
      <div class="range-input__solid" :style="{ width: toPixel(solidBarWidth) }" />
      <div ref="slider"
           class="range-input__slider"
           :style="sliderStyle" />
    </div>
  </div>
</template>

<script setup>
import useDrag from '@/composables/useDrag'
import {
  computed,
  defineEmits,
  defineProps,
  first,
  last,
  onMounted,
  onBeforeUnmount,
  parseEvent,
  ref,
  roundX,
  toPercent,
  toPixel,
  watch
} from '@/utils'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  labels: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Number,
    required: true
  },
  min: {
    type: Number,
    required: true
  },
  max: {
    type: Number,
    required: true
  },
  theme: String
})

const sliderWidth = 32
const sliderRadius = sliderWidth / 2

const labelBar = ref(null)
const bar = ref(null)
const slider = ref(null)
const sliderLeft = ref(-sliderRadius)
const pressed = ref(false)

const classname = computed(() => ({ [`range-input--${props.theme}`]: props.theme }))

const barLeft = computed(() => bar.value ? bar.value.getBoundingClientRect().left : 0)
const minLeft = computed(() => -sliderRadius)
const maxLeft = computed(() => {
  if (bar.value) {
    return bar.value.offsetWidth - barLeft.value + sliderRadius
  }
  return 0
})

const solidBarWidth = computed(() => sliderLeft.value + sliderRadius)
const scaleRows = computed(() => {
  if (! labelBar.value) {
    return []
  }
  const labelPosArr = Array.from(labelBar.value.children)
    .map(el => {
      const halfWidth = el.offsetWidth / 2
      return el.offsetLeft + halfWidth
    })
  const posArr = [0, ...labelPosArr, (bar.value.offsetWidth - sliderRadius)]
  const labelValues = props.labels.map(row => row.value)
  const values = [props.min, ...labelValues, props.max]
  return posArr.map((pos, i) => ({ pos, value: values[i] }))
})

const sliderStyle = computed(() => {
  const left = toPixel(sliderLeft.value)
  const transform = pressed.value ? 'scale(1.4)' : 'scale(1)'
  return { left, transform }
})

const labelFlexBasis = computed(() => {
  const labelCount = props.labels.length
  const spaceCount = labelCount - 1
  const count = labelCount + spaceCount
  return roundX(100 / count)
})

const setLabel = i => {
  const row = scaleRows.value[i + 1]
  const pos = row.pos + barLeft.value
  setSliderLeft(pos)
}

const getScalePair = fn => {
  const rows = scaleRows.value
  for (let i = 1; i < rows.length; i++) {
    const start = rows[i - 1]
    const end = rows[i]
    const matched = fn({ start, end })
    if (matched) {
      return { start, end }
    }
  }
  return null
}

const handleBarTouchClick = event => {
  const info = parseEvent(event)
  setSliderLeft(info.x)
}

const getModelValue = left => {
  const pos = left + sliderRadius
  const rows = scaleRows.value
  const firstScaleRow = first(rows)
  const lastScaleRow = last(rows)
  if (pos <= firstScaleRow.pos) {
    return firstScaleRow.value
  }
  if (pos >= lastScaleRow.pos) {
    return lastScaleRow.value
  }
  const pair = getScalePair(({ end }) => pos < end.pos)
  if (! pair) {
    return
  }
  const { start, end } = pair
  const ratio = (pos - start.pos) / (end.pos - start.pos)
  const total = end.value - start.value
  return roundX(start.value + (total * ratio))
}

const setSliderLeft = left => {
  let value = left - sliderRadius - barLeft.value
  const min = minLeft.value
  const max = maxLeft.value
  if (value < min) {
    value = min
  }
  if (value > max) {
    value = max
  }
  sliderLeft.value = value
  const modelValue = getModelValue(value)
  emit('update:modelValue', modelValue)
}

const getInitialSliderLeft = () => {
  const value = props.modelValue
  const rows = scaleRows.value
  const firstScaleRow = first(rows)
  const lastScaleRow = last(rows)

  if (value <= firstScaleRow.value) {
    return -sliderRadius
  }
  if (value >= lastScaleRow.value) {
    return bar.value.offsetWidth - sliderRadius
  }
  const pair = getScalePair(({ end }) => value <= end.value)
  if (! pair) {
    return 0
  }
  const { start, end } = pair
  const ratio = (value - start.value) / (end.value - start.value)
  const distance = end.pos - start.pos
  return roundX(start.pos + (distance * ratio) - sliderRadius)
}

onMounted(() => {
  sliderLeft.value = getInitialSliderLeft()
  const { left, unsubscribe } = useDrag(slider.value, {
    left: sliderLeft.value,
    pressed
  })
  watch(left, () => setSliderLeft(left.value))
  onBeforeUnmount(() => unsubscribe())
})
</script>

<style lang="scss" scoped>
.range-input {
  position: relative;
}
.range-input__label-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: .8em;
}
.range-input__label {
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  color: $color-primary;
}
.range-input__bar {
  position: relative;
  background: #e7e7e7;
  width: 100%;
  height: 12px;
}
.range-input__solid {
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  position: absolute;
  background: linear-gradient(45deg, #cb80c3, #8176e1);
  opacity: .48;
}
.range-input__slider {
  transition: .3s transform;
  border-radius: 50%;
  background: #d1d1d1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -16px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  box-shadow: -2px 0 1px 0 rgba(0, 0, 0, .3);
  @include size(32px);
}
.range-input.range-input--xdelivery {
  .range-input__solid {
    background: linear-gradient(45deg, #fff, #63d7cb);
  }
  .range-input__label {
    color: $color-xdelivery;
  }
}
</style>
