<template>
  <nav class="app-nav" :style="{ background }">
    <router-link class="app-nav__brand" to="/" @click="hideMenu">
      <brand-logo :icon-color="color"
                  :text-opacity="opacity" />
    </router-link>
    <burger-btn :cross="menuOpen"
                :color="color"
                @click="menuOpen = (! menuOpen)" />
    <teleport to="body">
      <div v-if="menuOpen" class="app-nav__menu">
        <router-link to="/features" @click="hideMenu">功能介紹</router-link>
        <router-link to="/case-studies" @click="hideMenu">成功案例</router-link>
        <router-link to="/pricing" @click="hideMenu">收費方式</router-link>
        <router-link to="/faqs" @click="hideMenu">常見問題</router-link>

        <div class="app-nav__section">
          <router-link to="/xdelivery" @click="hideMenu">火箭快遞<span class="app-nav__badge">新服務!</span></router-link>
          <router-link to="/contact" @click="hideMenu">我要申請<span class="app-nav__badge">限量開通!</span></router-link>
          <a @click="contactUs">聯絡我們</a>
        </div>
      </div>
    </teleport>
  </nav>
</template>

<script setup>
import BrandLogo from '@/components/BrandLogo'
import BurgerBtn from '@/components/BurgerBtn'
import useWindowScroll from '@/composables/useWindowScroll'
import useIntercom from '@/composables/useIntercom'
import {
  ref,
  colorGradient,
  computed,
  getScrollTopScale,
  useRoute,
  watch,
  isFn
} from '@/utils'

const { showIntercom } = useIntercom()

const colorPrimary = '#724a80'
const colorWhite = '#fff'
const context = { unwatch: null }
const route = useRoute()
const color = ref(colorWhite)
const opacity = ref(0)
const menuOpen = ref(false)
const hideMenu = () => menuOpen.value = false
const background = computed(() => `rgba(255, 255, 255, ${opacity.value})`)

const watchScroll = () => {
  const { scrollTop, unsubscribe } = useWindowScroll()
  const unwatchScrollTop = watch(scrollTop, () => {
    const top = scrollTop.value
    const colorScale = getScrollTopScale(top, 10, 30)
    color.value = colorGradient(colorScale, colorWhite, colorPrimary)
    const opacityScale = getScrollTopScale(top, 10, 100)
    opacity.value = opacityScale / 100
  })
  return () => {
    unsubscribe()
    unwatchScrollTop()
  }
}

const contactUs = () => {
  hideMenu()
  showIntercom()
}

const getSettings = route => {
  const useScrollAnimation = (route.name === 'HomePage')
  return {
    color: useScrollAnimation ? colorWhite : colorPrimary,
    opacity: useScrollAnimation ? 0 : 1,
    useScrollAnimation
  }
}

const init = () => {
  const settings = getSettings(route)
  const { useScrollAnimation } = settings
  const { unwatch } = context
  const hasScrollWatcher = isFn(unwatch)

  if (useScrollAnimation && (! hasScrollWatcher)) {
    context.unwatch = watchScroll()
  }
  else if ((! useScrollAnimation) && hasScrollWatcher) {
    unwatch()
    context.unwatch = null
  }
  color.value = settings.color
  opacity.value = settings.opacity
}

watch(() => route.name, init)
</script>

<style lang="scss" scoped>
.app-nav {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
}
.app-nav__brand {
  display: inline-flex;
  align-items: center;
}
.app-nav__menu {
  background: #f4f5f5;
  position: fixed;
  z-index: 1;
  top: $nav-height;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 0;
  > a {
    color: #606060;
    display: block;
    padding: 20px 34px;
    &:after {
      transform: translateY(20px);
      display: block;
      content: '';
      border-bottom: 1px solid #d5d6d6;
    }
    &:active,
    &:focus {
      background: rgba(114, 72, 128, .2);
      color: $color-primary;
    }
  }
}
.app-nav__section {
  margin-top: 4em;
  background: $color-primary;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 20px;
  margin-left: 1em;
  margin-right: 1em;
  > a {
    display: flex;
    align-items: center;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, .4);
    padding-top: .8em;
    padding-bottom: .8em;
    &:last-child {
      border-bottom: 0;
    }
  }
}
.app-nav__badge {
  background: #fff;
  color: $color-primary;
  display: inline-block;
  border-radius: 20px;
  padding: 0 .8em;
  margin-left: 1.2em;
  font-size: 12px;
}
</style>
