import {
  brandBgShareco,
  brandBgKp,
  brandBgRelove,
  brandBgHj,
  brandBgLao,
  brandBgKafen,
  brandBgMurfeeli,
  brandBgJkao,
  brandBgYk,
  brandBgMastersk,
  brandMobileDemoShareco,
  brandMobileDemoKp,
  brandMobileDemoRelove,
  brandMobileDemoHj,
  brandMobileDemoLao,
  brandMobileDemoKafen,
  brandMobileDemoMurfeeli,
  brandMobileDemoJkao,
  brandMobileDemoYk,
  brandMobileDemoMastersk
} from '@/consts/img'
import { keyBy } from '@/utils'

export const BRAND_TYPE_INDIE = 'indie-brand'
export const BRAND_TYPE_KOL = 'kol'

export const BRAND_TYPE_ITEMS = [
  { text: '獨立品牌', value: BRAND_TYPE_INDIE },
  { text: 'KOL', value: BRAND_TYPE_KOL }
]

export const BRAND_TYPE_MAP = keyBy(BRAND_TYPE_ITEMS, 'value')

export const BRAND_ITEMS = [
  {
    id: 'shareco',
    title: 'SHARECO',
    desc: '潮牌設計師以 20 萬元資金獨立創業，以「香水吊卡」起家，運用 FB、IG 經營社群導流，加上一頁商店的「高轉換率」，突破小眾市場的劣勢，從每個月入不敷出，穩健成長為年營收破億的香氛生活品牌，目前擁有五大產品線，並走入線下進駐百貨專櫃，明星瘦子 E.SO 更連續兩年擔任代言人。',
    bgUrl: brandBgShareco.src,
    bgConfig: brandBgShareco,
    bgAlt: '三個 SHARECO 的香水瓶陳列在海灘的礁岩上',
    logoUrl: require('@/assets/brand-logo-shareco.svg'),
    logoAlt: 'SHARECO',
    mobileDemoImgUrl: brandMobileDemoShareco.src,
    mobileDemoImgConfig: brandMobileDemoShareco,
    mobileDemoAlt: '瘦子 E.SO 全新騷包香味，使壞登場 ! 連潮流雜誌編輯部都上癮的香氛',
    mobileDemoWidth: 260,
    mobileDemoHeight: 522,
    url: 'https://www.shareco.me/',
    type: BRAND_TYPE_INDIE
  },
  {
    id: 'kplove',
    title: 'KLOWER PANDOR',
    desc: '女性沐浴品、香氛市場競爭激烈，KP 女王香氛以精準的品牌定位，加上 KOL 行銷操作，打造出「爆品銷售頁」，運用粉絲經濟一舉打開市場知名度。人氣商品曾獲得 VOGUE 雜誌專文介紹、連續兩屆獲邀聯名金馬影展貴賓專屬禮品，目前於台北、高雄等地開設多家百貨門市，持續往台灣第一女性香氛保養品牌前進。',
    bgUrl: brandBgKp.src,
    bgConfig: brandBgKp,
    bgAlt: '兩瓶沐浴品放在浴缸上分別寫著 KLOWER PANDOR BODY WASH 與 KLOWER PANDOR SHAMPOO',
    logoUrl: require('@/assets/brand-logo-kp.svg'),
    logoAlt: 'KLOWER PANDOR',
    mobileDemoImgUrl: brandMobileDemoKp.src,
    mobileDemoImgConfig: brandMobileDemoKp,
    mobileDemoAlt: '氣質誘人，粲花精靈。全新香味，氣勢來襲! NEW LISTING ! 格雷男爵，性感魅力，KLOWER PANDOR。自帶體香的秘密，從洗澡開始。散發挑戰感官的誘惑魅力',
    mobileDemoWidth: 260,
    mobileDemoHeight: 520,
    url: 'https://www.kplove.co/',
    type: BRAND_TYPE_INDIE
  },
  {
    id: 'relove',
    title: 'Relove',
    desc: '生技代工廠力拼轉型成立自有品牌，以「官方網站 ＋ 一頁商店」的雙渠道模式，結合商城式網站與一頁商店的優勢，提升整體銷售額，打造出精品級「女性私密衣物手洗精、私密護理噴霧產品」，獲得徐若瑄等多位女星好評推薦，被媒體譽為私密保養品界的愛馬仕！',
    bgUrl: brandBgRelove.src,
    bgConfig: brandBgRelove,
    bgAlt: '滿滿的 Relove 產品',
    logoUrl: require('@/assets/brand-logo-relove.svg'),
    logoAlt: 'Relove',
    mobileDemoImgUrl: brandMobileDemoRelove.src,
    mobileDemoImgConfig: brandMobileDemoRelove,
    mobileDemoAlt: '為女性私密肌「淨」全利，私密肌的千萬問題交給他，氨基酸私密潔淨凝露系列。大家好我是 Vivian，我只用最好的，所以我選擇 RELOVE',
    mobileDemoWidth: 260,
    mobileDemoHeight: 523,
    url: 'https://chillman.co/',
    type: BRAND_TYPE_INDIE
  },
  {
    id: 'huang-jun',
    title: 'Huang Jun',
    desc: '本身擁有多間實體門市的 H & J，是創立於南台灣的服飾品牌，幾年前選擇一頁商店跨入電商版圖，精選實體門市的熱銷商品上架，拓展網路客群。一頁商店的高轉單率，稀釋獲取新客的成本，並吸引舊客持續回購，搭配藝人推薦和特定檔期活動，成功搶下女裝市場 2 億多營業額！',
    bgUrl: brandBgHj.src,
    bgConfig: brandBgHj,
    bgAlt: '三條牛仔褲上面有 Huang Jun 的愛心形狀紙招牌',
    logoUrl: require('@/assets/brand-logo-hj.svg'),
    logoAlt: 'Huang Jun',
    mobileDemoImgUrl: brandMobileDemoHj.src,
    mobileDemoImgConfig: brandMobileDemoHj,
    mobileDemoAlt: '-3kg Jeans 魔法顯瘦褲，「國民女神褲」',
    mobileDemoWidth: 260,
    mobileDemoHeight: 526,
    url: 'https://www.lovely.company/',
    type: BRAND_TYPE_INDIE
  },
  {
    id: 'laopengyu',
    title: '老彭友',
    desc: '資深總鋪師端出拿手小菜進軍電商，掌握一頁商店銷售頁「資訊集中」的特性，讓精心製作的行銷素材發揮最大效益，緊緊抓住消費者目光，嘴饞可以快速下單結帳，把宜蘭小菜秘制鴨掌、獨家手作雞翅，搖身變成網友呷好道相報的下酒良伴，每月平均營業額超過 400 萬。',
    bgUrl: brandBgLao.src,
    bgConfig: brandBgLao,
    bgAlt: '秘制鴨掌、秘制黃瓜與秘制泡菜',
    logoUrl: require('@/assets/brand-logo-lao.png'),
    logoAlt: '老彭友',
    mobileDemoImgUrl: brandMobileDemoLao.src,
    mobileDemoImgConfig: brandMobileDemoLao,
    mobileDemoAlt: '秘制系列',
    mobileDemoWidth: 260,
    mobileDemoHeight: 522,
    url: 'https://www.laopengyu.co/',
    type: BRAND_TYPE_INDIE
  },
  {
    id: 'kafen',
    title: 'KA\'FEN',
    desc: '由 30 年資歷的美髮職人和七年級生行銷團隊，聯手推出洗護髮品牌KAFEN。從原本超過 50 項的產品系列中，精選出 15 項主打商品上架，透過「站台功能」重新分類，彌補官網資訊量太多、不易閱讀的問題，讓消費者更容易了解商品特色，找到符合需求的方案，提升品牌整體獲利。',
    bgUrl: brandBgKafen.src,
    bgConfig: brandBgKafen,
    bgAlt: 'KA\'FEN DEEP RECOVERY TREATMENT 與淡色系玫瑰花',
    logoUrl: require('@/assets/brand-logo-kafen.svg'),
    logoAlt: 'Kafen',
    mobileDemoImgUrl: brandMobileDemoKafen.src,
    mobileDemoImgConfig: brandMobileDemoKafen,
    mobileDemoAlt: 'KA\'FEN 特潤柔順髮，買 1 送 1，特價 900',
    mobileDemoWidth: 260,
    mobileDemoHeight: 520,
    url: 'https://hair.kafen.com.tw/',
    type: BRAND_TYPE_INDIE
  },
  {
    id: 'murfeeli',
    title: 'MURFEELI',
    desc: '一項商品也能成為創業起點！李焰本來是朝九晚五的小資族，2016 年開始在網路上分享自己的購物心得，並嘗試賣一款自己很喜歡的包包，在一頁商店獲得超高轉單量，決定全職投入創業。精準的眼光加上賓至如歸的客戶服務，迅速累積品牌知名度，用口碑贏得千萬業績，短短三年從小資族晉升為品牌 CEO。',
    bgUrl: brandBgMurfeeli.src,
    bgConfig: brandBgMurfeeli,
    bgAlt: '黑色與白色的百搭鏈條包',
    logoUrl: require('@/assets/brand-logo-murfeeli.svg'),
    logoAlt: 'MURFEELI',
    mobileDemoImgUrl: brandMobileDemoMurfeeli.src,
    mobileDemoImgConfig: brandMobileDemoMurfeeli,
    mobileDemoAlt: 'New Colors! 新色登場! 3天賣斷貨的百搭鍊條包，終於出新色!',
    mobileDemoWidth: 260,
    mobileDemoHeight: 523,
    url: 'https://www.murfeeli.co/',
    type: BRAND_TYPE_KOL
  },
  {
    id: 'jkao',
    title: 'J.KAO',
    desc: '知名八點檔女星「高宇蓁」創立精品保養品品牌，首次做商店，看中一頁商店「操作簡單、高轉換率」的特性，運用自有的社群流量操作廣告，上市首周就賣到缺貨，後續推出的每款商品幾乎都被粉絲秒殺，許多藝人都是愛用者，成為保養品界的明星級品牌。',
    bgUrl: brandBgJkao.src,
    bgConfig: brandBgJkao,
    bgAlt: '三罐印有 J.KAO 字樣的保養品',
    logoUrl: require('@/assets/brand-logo-jkao.svg'),
    logoAlt: 'J.KAO',
    mobileDemoImgUrl: brandMobileDemoJkao.src,
    mobileDemoImgConfig: brandMobileDemoJkao,
    mobileDemoAlt: '5分鐘逆時面膜',
    mobileDemoWidth: 260,
    mobileDemoHeight: 523,
    url: 'https://www.jkao.co/',
    type: BRAND_TYPE_KOL
  },
  {
    id: 'yk',
    title: '宥凱 YU KAI',
    desc: '擁有 15 萬粉絲的網路直播主宥凱，透過臉書直播搭配一頁商店收單，以個人形象建立信任感，成功吸引精準族群，是運用粉絲經濟的絕佳範例。每次直播結束後，再剪輯成精華版影片，透過臉書廣告做 RT「再行銷」，將沒看到直播的潛在客戶一網打盡，獲利再加 30%。',
    bgUrl: brandBgYk.src,
    bgConfig: brandBgYk,
    bgAlt: '直播主宥凱蹲在滿滿的貨品紙箱前',
    logoUrl: require('@/assets/brand-logo-yk.svg'),
    logoAlt: 'Y.K',
    mobileDemoImgUrl: brandMobileDemoYk.src,
    mobileDemoImgConfig: brandMobileDemoYk,
    mobileDemoAlt: '水極光，清潔卸妝，還原你的水嫩肌',
    mobileDemoWidth: 260,
    mobileDemoHeight: 523,
    url: 'https://www.yukai.ceo/',
    type: BRAND_TYPE_KOL
  },
  {
    id: 'mastersk',
    title: '聖凱師',
    desc: '粉絲「黏著度極高」的百萬聖凱師，在一頁商店舉辦「檔期限定」菜色網購，如年菜、中秋端午檔期、限量食材搶購等， 社群導流轉單成效顯著，加上便捷的下單流程，達成兩個月熱銷一萬多組的超強成績，「多人協作的即時訂單」，還能輕鬆消化大量訂單，有效降低出錯錯率，減少不必要的成本耗損。',
    logoUrl: require('@/assets/brand-logo-sk.svg'),
    logoAlt: '錵鑶',
    bgUrl: brandBgMastersk.src,
    bgConfig: brandBgMastersk,
    bgAlt: '一碗麻辣拌麵',
    mobileDemoImgUrl: brandMobileDemoMastersk.src,
    mobileDemoImgConfig: brandMobileDemoMastersk,
    mobileDemoAlt: '錵鑶團圓年菜',
    mobileDemoWidth: 260,
    mobileDemoHeight: 523,
    url: 'https://www.hanafood.cc/',
    type: BRAND_TYPE_KOL
  }
]

export const BRAND_MAP = keyBy(BRAND_ITEMS, 'id')
