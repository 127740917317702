<template>
  <div ref="root" class="img-slider">
    <div class="img-slider__box" :class="boxClass">
      <img-slide v-for="(item, i) in items"
                 class="img-slider__slide"
                 :key="item.id"
                 :item="item"
                 :style="getSlideStyle(i)" />
    </div>
  </div>
</template>

<script setup>
import ImgSlide from '@/components/ImgSlide'
import useDrag from '@/composables/useDrag'
import {
  computed,
  defineProps,
  onBeforeUnmount,
  onMounted,
  ref,
  toPixel,
  watch
} from '@/utils'

const root = ref(null)
const imageWidth = 274
const depth = 70
const deltaX = ref(0)
const pressed = ref(false)

const props = defineProps({
  items: {
    type: Array,
    required: true
  }
})

const currentIndex = ref(0)

const boxClass = computed(() => `img-slider__box--slide${currentIndex.value}`)

const getSlideStyle = i => {
  const isCurrent = (i === currentIndex.value)
  const delta = Math.abs(i - currentIndex.value)
  let x = i * (imageWidth * .2)
  if (isCurrent) {
    x += deltaX.value
  }
  const z = -delta * depth
  const scale = (isCurrent && pressed.value) ? 1.05 : 1
  const transform = `translate3d(${toPixel(x)}, 0, ${toPixel(z)}) scale(${scale})`
  return { transform }
}

const goPrev = () => {
  if (currentIndex.value > 0) {
    currentIndex.value -= 1
  }
}

const goNext = () => {
  if (currentIndex.value < (props.items.length - 1)) {
    currentIndex.value += 1
  }
}

watch(pressed, () => {
  if (pressed.value) {
    return
  }
  const x = deltaX.value

  // skip vertical swipe
  if (Math.abs(x) < 5) {
    deltaX.value = 0
    return
  }
  const swipeLeft = x < 0
  const swipeRight = x > 0
  if (swipeRight) {
    goPrev()
  }
  if (swipeLeft) {
    goNext()
  }
  deltaX.value = 0
})

onMounted(() => {
  const { unsubscribe } = useDrag(root.value, { pressed, deltaX })
  onBeforeUnmount(() => unsubscribe())
})
</script>

<style lang="scss" scoped>
.img-slider {
  overflow: hidden;
  padding-top: 1.4em;
  padding-bottom: 1.4em;
}
.img-slider__box {
  user-select: none;
  position: relative;
  perspective: 500px;
  transition: .3s transform;
  transform-style: preserve-3d;
  width: 274px;
  height: 450px;
  margin-left: 1.3em;
  margin-right: 1.3em;
  &.img-slider__box--slide1 {
    transform: translateX(-25px);
  }
  &.img-slider__box--slide2 {
    transform: translateX(-50px);
  }
}
.img-slider__slide {
  position: absolute;
  transition: .3s transform;
}
</style>
