import {
  slideShareco,
  slideMurfeeli,
  slideKp,
  rectShareco,
  rectKp,
  rectRelove,
  rectHj,
  rectLaopengyu,
  rectKafen,
  rectMurfeeli,
  rectJkao,
  rectYk,
  rectMastersk
} from '@/consts/img'

export const SLIDES = [
  {
    id: 1,
    src: slideShareco.src,
    imgConfig: slideShareco,
    alt: '海灘珊瑚礁上陳列著三罐 SHARECO 的香水 - SHARECO',
    quote: '一頁商店非常好用，讓我能更專注打造好的香水產品！',
    author: 'SHARECO 主理人',
    to: '/brand/shareco'
  },
  {
    id: 2,
    src: slideMurfeeli.src,
    imgConfig: slideMurfeeli,
    alt: '李焰面帶微笑拿著一罐保養品 - MURFEELI',
    quote: '把商品和服務做到極致，一項商品也能得到消者 100% 的支持',
    author: 'MURFEELI 創辦人 李焰',
    to: '/brand/murfeeli'
  },
  {
    id: 3,
    src: slideKp.src,
    imgConfig: slideKp,
    alt: '三樣 Klower Pandor 的產品',
    quote: '把用心開發的產品放在對的舞台，你可以得到超乎預期的迴響',
    author: 'KP 記憶香氛 主理人 KAY',
    to: '/brand/kplove'
  }
]

export const INDIE_ITEMS = [
  {
    id: 1,
    src: rectShareco.src,
    imgConfig: rectShareco,
    alt: 'SHARECO',
    to: '/brand/shareco'
  },
  {
    id: 2,
    src: rectKp.src,
    imgConfig: rectKp,
    alt: 'KLOWER PANDOR',
    to: '/brand/kplove'
  },
  {
    id: 3,
    src: rectRelove.src,
    imgConfig: rectRelove,
    alt: 'Relove',
    to: '/brand/relove'
  },
  {
    id: 4,
    src: rectHj.src,
    imgConfig: rectHj,
    alt: 'HUANG JUN',
    to: '/brand/huang-jun'
  },
  {
    id: 5,
    src: rectLaopengyu.src,
    imgConfig: rectLaopengyu,
    alt: '老彭友',
    to: '/brand/laopengyu'
  },
  {
    id: 6,
    src: rectKafen.src,
    imgConfig: rectKafen,
    alt: 'KA\'FEN',
    to: '/brand/kafen'
  }
]

export const KOL_ITEMS = [
  {
    id: 7,
    src: rectMurfeeli.src,
    imgConfig: rectMurfeeli,
    alt: 'MURFEELI',
    to: '/brand/murfeeli'
  },
  // JKAO 名字侵權，改名了先下架 (避免被告)
  // {
  //   id: 8,
  //   src: rectJkao.src,
  //   imgConfig: rectJkao,
  //   alt: 'J.KAO',
  //   to: '/brand/jkao'
  // },
  {
    id: 9,
    src: rectYk.src,
    imgConfig: rectYk,
    alt: '百萬直播主宥凱',
    to: '/brand/yk'
  },
  {
    id: 10,
    src: rectMastersk.src,
    imgConfig: rectMastersk,
    alt: '聖凱師',
    to: '/brand/mastersk'
  }
]
