import useWindowScroll from '@/composables/useWindowScroll'
import { ref, watch, useRoute } from '@/utils'

export default function useTopBtn() {

  const route = useRoute()
  const topBtnVisible = ref(false)
  const { scrollTop } = useWindowScroll()

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  watch(scrollTop, () => {
    const multiple = (route.name === 'XdeliveryPage') ? 1 : 4
    topBtnVisible.value = scrollTop.value > (window.innerHeight * multiple)
  })

  return {
    topBtnVisible,
    scrollToTop
  }
}
