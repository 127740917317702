<template>
  <div v-visibility="handleVisibilityChange" class="contact-box__wrap">
    <div class="contact-box" :class="classname">
      <div class="contact-box__bg" />
      <div class="contact-box__content">
        <h3>先跟你說聲抱歉<br>一頁商店不適合所有賣家</h3>
        <div class="contact-box__divider" />
        <div class="contact-box__info">
          <div>通過審核後我們會主動寄出通知信，免綁約、免月費，收到信件即可登入使用，打造你的百萬商店！</div>
          <app-img class="contact-box__img"
                   alt="客服人員戴著耳機回覆訊息"
                   :src="require('@/assets/customer-service-representative.svg')" />
        </div>
        <app-btn class="contact-box__btn"
                 arrow
                 block
                 to="/contact"
                 :light="dark">立即免費申請</app-btn>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppBtn from '@/components/AppBtn'
import AppImg from '@/components/AppImg'
import useWindowScroll from '@/composables/useWindowScroll'
import { computed, ref, watch, onBeforeUnmount } from '@/utils'

const dark = ref(false)
const { scrolling, unsubscribe } = useWindowScroll()
const classname = computed(() => ({ 'contact-box--dark': dark.value }))

let isIntersecting = false

const handleVisibilityChange = entry => {
  isIntersecting = entry.isIntersecting
  if (! isIntersecting) {
    dark.value = false
  }
}
watch(scrolling, () => {
  // animate when user stops scrolling
  if ((! scrolling.value) && isIntersecting) {
    dark.value = true
  }
})
onBeforeUnmount(() => unsubscribe())
</script>

<style lang="scss" scoped>
.contact-box h3,
.contact-box__divider,
.contact-box__info,
.contact-box__btn {
  transition: .3s all;
}
.contact-box__bg {
  transition: .7s all;
}
.contact-box__wrap {
  background: linear-gradient(to bottom, #fff 50%, $bg-footer 50%);
}
.contact-box {
  border-radius: 24px;
  background: linear-gradient(0, #3c267e, #724a88);
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .3);
  h3 {
    color: $color-primary;
  }
}
.contact-box__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  transform: translate(100px, 400px) rotate(152deg) scale(1.4, 1.4);
  transform-origin: top;
}
.contact-box__content {
  position: relative;
  padding: 1.6em 1.5em 2em;
}
.contact-box__divider {
  background: $color-primary;
  height: 1px;
  width: 128px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.contact-box__info {
  display: flex;
  align-items: flex-start;
  color: $color-primary;
  font-size: .875rem;
}
.contact-box__img {
  width: 180px;
  flex-shrink: 0;
  margin-left: .8em;
}
.contact-box__btn {
  margin-top: 1.5em;
}
.contact-box__arrow {
  width: 22px;
  height: auto;
  flex-shrink: 0;
  display: block;
  stroke: #fff;
  margin-left: .6em;
}
.contact-box.contact-box--dark {
  h3 {
    color: #fff;
  }
  .contact-box__divider {
    background: #fff;
  }
  .contact-box__info {
    color: #fff;
  }
  .contact-box__arrow {
    stroke: $color-primary;
  }
  .contact-box__bg {
    transform: translate(100px, 400px) rotate(152deg) scale(1.4, 0);
  }
}
</style>
