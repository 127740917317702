import '@/styles/main.scss'
import '~/official-common/scripts/ga'
import { createApp } from '@/utils'
import App from '@/pages/App.vue'
import router from '@/router'
import visibility from '@/directives/visibility'
import anchor from '@/directives/anchor'
import autosize from '@/directives/autosize'

const app = createApp(App)

app.directive('visibility', visibility())
app.directive('anchor', anchor)
app.directive('autosize', autosize())
app.use(router)
app.mount('#app')
