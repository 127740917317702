import { reactive, extend } from '@/utils'

export default function useValidation(context) {

  const { form, validators, refs } = context
  const errScrollable = context.errScrollable || true

  const errs = reactive({})
  const keys = Object.keys(validators)

  const validate = () => {

    keys.forEach(key => {
      const fn = validators[key]
      const value = form[key]
      errs[key] = extend({ key }, fn(value, form))
    })
    const success = keys.every(key => errs[key].errs.length === 0)
    if ((! success) && errScrollable) {
      scrollToFirstErr()
    }
    return success
  }

  const err = {
    first(key) {
      const firstErr = errs[key] && errs[key].errs[0]
      return firstErr ? firstErr.msg : null
    },
    firstErrKey() {
      const firstErr = keys.map(key => errs[key])
        .find(row => row.errs.length > 0)
      return firstErr ? firstErr.key : null
    }
  }
  const setRef = prop => el => {
    refs.value[prop] = el
  }
  const scrollToFirstErr = () => {
    const firstErrKey = err.firstErrKey()
    const tmplRef = refs.value[firstErrKey]
    const el = tmplRef && tmplRef.$el
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
  const setErrs = fieldErrs => {
    Object.keys(fieldErrs)
      .forEach(key => {
        errs[key] = {
          type: 'server-error',
          key,
          errs: fieldErrs[key].map(msg => ({ msg }))
        }
      })
    scrollToFirstErr()
  }
  return { validate, err, setRef, setErrs }
}
