<template>
  <div>
    <app-nav />
    <h1 class="visually-hidden">一頁商店</h1>
    <router-view />
    <app-footer />
    <top-btn v-if="topBtnVisible" @click="scrollToTop" />
  </div>
</template>

<script setup>
import AppFooter from '@/components/AppFooter'
import AppNav from '@/components/AppNav'
import TopBtn from '@/components/TopBtn'
import useTopBtn from '@/composables/useTopBtn'
import { onMounted } from '@/utils'

const { topBtnVisible, scrollToTop } = useTopBtn()

// This is for prerender-spa-plugin to capture
onMounted(() => document.dispatchEvent(new Event('app-mounted')))
</script>
