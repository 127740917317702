<template>
  <div class="xdelivery-calculator">
    <div class="xdelivery-calculator__title">試算範例說明</div>
    <div class="text-xdelivery">包裹出貨數量</div>
    <range-input v-model="shippingCount"
                 theme="xdelivery"
                 :labels="PRICING_LABELS"
                 :min="0"
                 :max="700" />
    <xdelivery-pricing-table :value="shippingCount" />
  </div>
</template>

<script setup>
import RangeInput from '@/components/RangeInput'
import XdeliveryPricingTable from '@/components/XdeliveryPricingTable'
import { ref } from '@/utils'
import { PRICING_LABELS } from '~/official-common/consts/xdelivery'

const shippingCount = ref(150)
</script>

<style lang="scss" scoped>
.xdelivery-calculator {
  margin-top: 3em;
  position: relative;
  border: 1px solid #ddd;
  padding: 1.5em .8em 1em;
  font-size: .875rem;
}
.xdelivery-calculator__title {
  padding-left: .3em;
  padding-right: .3em;
  display: block;
  width: fit-content;
  position: absolute;
  top: -14px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #a0a0a0;
  color: #fff;
}
.xdelivery-calculator__table {
  background: linear-gradient(-45deg, #f5e5f4, #e6e3f9);
  padding: 1em;
  margin-top: 2.4em;
  > table {
    width: 100%;
  }
}
.xdelivery-calculator__lv-text-cell {
  font-weight: 500;
}
.xdelivery-calculator__lv-value-cell {
  text-align: right;
}
.xdelivery-calculator__lv-subtotal-cell {
  text-align: right;
}
</style>
