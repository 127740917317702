<template>
  <div class="landing-hero">
    <h2>一頁商店為轉單而生 !<br>打造業績狂飆的銷售火箭 !</h2>
    <h3>平均轉換率 3-8%，比商城高出 5 倍以上，一兩樣產品也能衝破百萬營收，輕鬆電商起步 !</h3>
    <app-img class="landing-hero__img"
             alt="很多對話視窗浮在桌機與手機螢幕上方，桌機螢幕中有飛翔中的一頁商店火箭圖示"
             width="330"
             height="242"
             :src="require('@/assets/hero.svg')"
             :style="style" />
  </div>
</template>

<script setup>
import AppImg from '@/components/AppImg'
import useWindowScroll from '@/composables/useWindowScroll'
import {
  computed,
  getScrollTopScale,
  onBeforeUnmount,
  ref,
  toPixel,
  watch
} from '@/utils'

const { scrollTop, unsubscribe } = useWindowScroll()
const y = ref(0)
const style = computed(() => ({
  transform: `translateY(${toPixel(y.value)})`
}))

watch(scrollTop, () => {
  const scale = getScrollTopScale(scrollTop.value, 180, 420)
  y.value = scale * 0.9
})
onBeforeUnmount(() => unsubscribe())
</script>

<style lang="scss" scoped>
.landing-hero {
  padding: 5.8em 1.4em 4.5em;
  background: linear-gradient(120deg, #3c267e, #724a88);
  color: #fff;
  h2 {
    font-size: 1.7rem;
  }
  h3 {
    margin-top: 1em;
    margin-bottom: 2.7em;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.7;
  }
}
</style>
