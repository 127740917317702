<template>
  <component v-bind="attrs"
             class="app-btn"
             :class="classname"
             :is="tag">
    <app-loader v-if="loading" />
    <template v-else>
      <slot />
      <app-icon v-if="arrow"
                class="app-btn__arrow"
                name="arrow-right" />
    </template>
  </component>
</template>

<script setup>
import AppIcon from '@/components/AppIcon'
import AppLoader from '@/components/AppLoader'
import { defineProps, extend, computed, hyphenate, useAttrs } from '@/utils'

const originalAttrs = useAttrs()

const props = defineProps({
  light: Boolean,
  dark: Boolean,
  darkGradient: Boolean,
  gay: Boolean,
  xdelivery: Boolean,
  outline: Boolean,
  arrow: Boolean,
  block: Boolean,
  sm: Boolean,
  rect: Boolean,
  loading: Boolean
})

const tag = (() => {
  const { to, href } = originalAttrs
  if (to) {
    return 'router-link'
  }
  if (href) {
    return 'a'
  }
  return 'button'
})()

const attrs = computed(() => {
  if (props.loading && (tag === 'button')) {
    return extend({}, originalAttrs, { disabled: 'disabled' })
  }
  return originalAttrs
})

const btnType = computed(() => {
  const types = ['light', 'dark', 'darkGradient', 'xdelivery', 'gay', 'outline']
  return types.find(key => props[key]) || 'dark'
})
const classname = computed(() => ({
  [`app-btn--${hyphenate(btnType.value)}`]: true,
  'app-btn--block': props.block,
  'app-btn--rect': props.rect,
  'app-btn--sm': props.sm
}))
</script>

<style lang="scss" scoped>
.app-btn {
  font-size: 1.2rem;
  font-weight: 600;
  transition: .3s all;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .3);
  padding: 0 1em;

  &.app-btn--block {
    display: flex;
    width: 100%;
  }
  &.app-btn--rect {
    box-shadow: none;
    border-radius: 6px;
    padding: 2px 10px;
  }
  &.app-btn--sm {
    font-size: .875rem;
    font-weight: 400;
  }
  &.app-btn--dark {
    background: $color-primary;
    color: #fff;
    .app-btn__arrow {
      stroke: #fff;
    }
  }
  &.app-btn--outline {
    background: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;
    .app-btn__arrow {
      stroke: #color-primary;
    }
    &:focus {
      background: $color-primary;
      color: #fff;
    }
  }
  &.app-btn--dark-gradient {
    background: linear-gradient(-45deg, $color-primary-ex, $color-primary);
    color: #fff;
    .app-btn__arrow {
      stroke: #fff;
    }
  }
  &.app-btn--light {
    background: #fff;
    color: $color-primary;
    .app-btn__arrow {
      stroke: $color-primary;
    }
  }
  &.app-btn--gay {
    background: #aa1d4e;
    color: #fff;
    .app-btn__arrow {
      stroke: #fff;
    }
  }
  &.app-btn--xdelivery {
    background: #fff;
    color: $color-xdelivery;
    .app-btn__arrow {
      stroke: $color-xdelivery;
    }
  }
}
.app-btn__arrow {
  font-weight: 900;
  flex-shrink: 0;
  margin-left: .6em;
  display: inline-block;
}
a.app-btn {
  display: inline-flex;
}
</style>
