<template>
  <picture class="app-img">
    <source v-if="config.avif" type="image/avif" :srcset="srcset(config.avif)" />
    <source v-if="config.webp" type="image/webp" :srcset="srcset(config.webp)" />
    <img class="app-img__img"
         loading="lazy"
         draggable="false"
         :class="classname"
         :src="src"
         :srcset="srcset(config.srcset)"
         :alt="alt"
         :width="width"
         :height="height"
         @load="onLoad"
         @error="onLoad">
  </picture>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from '@/utils'

const emit = defineEmits(['load'])
defineProps({
  src: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    required: true
  },
  width: [String, Number],
  height: [String, Number],
  config: {
    type: Object,
    default: () => ({})
  }
})
const loaded = ref(false)
const classname = computed(() => ({ 'app-img__img--loaded': loaded.value }))
const onLoad = () => {
  loaded.value = true
  emit('load')
}
const srcset = extConfig => {
  if (! extConfig) {
    return null
  }
  return ['1x', '2x', '3x'].map(key => {
    return { key, path: extConfig[key] }
  })
  .filter(row => row.path)
  .map(row => `${row.path} ${row.key}`)
  .join(' ,')
}
</script>

<style lang="scss" scoped>
.app-img {
  display: block;
}
.app-img__img {
  transition: .3s opacity;
  opacity: 0;
  &.app-img__img--loaded {
    opacity: 1;
  }
}
</style>
