import { extend } from '@/utils'

export const FAQ_ITEMS = [
  {
    title: '我適合使用一頁商店嗎?',
    desc: '販售實體商品的店家，都適合使用一頁商店。不過一頁式銷售頁「品項少、轉換高」的特性，和一般網拍平台、購物商城十分不同，特別推薦給有廣告投放經驗 / 擁有粉絲群的網紅、部落客 / 已經在經營電商的店家使用。'
  },
  {
    title: '需要綁約嗎？ 怎麼收費？',
    desc: '使用一頁商店僅需繳交系統使用費(有收到訂單才會開始計費)無需綁約、免繳系統開通費，也不收最低費用，詳細的收費方式請至「收費方式」參考說明。'
  },
  {
    title: '可以販售哪些商品？',
    desc: '基本上符合法規的「實體商品」，都可以使用一頁商店做販售，不過實際上會以審核小組的評估結果為準，如果有興趣，歡迎提出申請，有任何問題也可以詢問線上客服人員。',
    hasApplyBtn: true
  },
  {
    title: '可以免費試用嗎？',
    desc: '使用一頁商店實際收單後，才需要支付平台使用費，因此只要提出申請、通過審核的店家，就可以直接試用所有功能喔。'
  },
  {
    title: '操作起來會不會很難？',
    desc: '一頁商店的開發團隊，專注在最重要、最常用的功能上，因此系統操作十分簡便，加上完整的教學文件，有任何使用問題，也可以隨時洽詢線上客服，所以新手也能夠很快上手喔。'
  },
  {
    title: '一頁商店會幫我操作廣告嗎？',
    desc: '一頁商店目前僅提供平台收單功能，並無提供廣告行銷代操服務。'
  },
  {
    title: '一頁商店是否會設計銷售頁？',
    desc: '一頁商店目前僅提供平台收單功能，銷售頁的圖文內容，需請店家自行設計、撰寫。'
  },
  {
    title: '為什麼需要審核？',
    desc: '由於一頁商店獨特的系統特性與行銷架構，和一般電商平台不同，加上店家需要自行操作廣告及設計銷售頁面，適合有一定電商經驗的店家。因此我們成立以來，審核小組根據店家的申請資料，謹慎評估平台的適用度，希望推薦給適合的店家，發揮系統最大效益，幫事業有效成長。'
  },
  {
    title: '審核需要多久？',
    desc: '申請審核約需 3-5 個工作天(不含六日及例假日)，通過審核後，系統會主動寄出登入資訊通知店家。'
  },
  {
    title: '申請超過 5 天，尚未收到通知？',
    desc: '由於申請資料繁多，目前我們僅會針對通過審核的店家寄出通知信件，若未通過審核，恕不另行通知。若對審核進度有任何疑問，可以洽詢線上客服人員。'
  },
  {
    title: '收到需補充資料的通知？',
    desc: '審核人員為了瞭解更多商品資訊及營運方式，會透過系統發送「請補充更多資料」的通知，建議在填寫申請表時，盡可能詳細提供「產品內容、粉絲團、網站、其他賣場」等資訊，讓審核人員更容易認識你喔。'
  }
].map((row, i) => {
  const key = `faq-${i + 1}`
  return extend({}, row, { key })
})
