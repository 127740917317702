<template>
  <div class="landing-proof">
    <div class="landing-proof__hero">
      <app-img class="landing-proof__bg"
               alt="多個人使用筆電一起討論事情"
               :src="bgProof.src"
               :config="bgProof" />
      <div class="landing-proof__hero-content">
        <h3>我們自己驗證過!<br>千錘百鍊的電商平台</h3>
        <div class="landing-proof__desc">我們擁有自己經營的電商品牌</div>
      </div>
      <div v-visibility="handleVisibilityChange"
           class="landing-proof__logo-box"
           :class="logoClass">
        <div class="landing-proof__logo">
          <app-img alt="SHARECO"
                   width="82"
                   height="12"
                   :src="require('@/assets/proof-shareco.svg')" />
        </div>
        <div class="landing-proof__logo">
          <app-img alt="KLOWER PANDOR"
                   width="82"
                   height="33"
                   :src="require('@/assets/proof-kp.svg')" />
        </div>
      </div>
    </div>
    <div class="landing-proof__how">
      <div class="landing-proof__list">
        <proof-right>公司電商部門: 35 人</proof-right>
        <proof-right>品牌年營收: 5 億元</proof-right>
        <div>你會遇到的問題，我們團隊早就遇過了</div>
        <proof-wrong>訂單處理功能寫不好（營運同事抱怨連連）</proof-wrong>
        <proof-wrong>行銷功能做不好（行銷人員綁手綁腳）</proof-wrong>
        <proof-wrong>銷售頁功能做不好（客服同事）</proof-wrong>
      </div>
      <div class="landing-proof__eddie-statement">
        捨棄許多華而不實的功能，取而代之的是把營運人員每天都會重複操作的項目，優化到極致，從根基提升運作率，確保團隊發揮最大效益！
        <div class="landing-proof__sign-box">
          <app-img class="landing-proof__sign"
                   alt="Eddie 的簽名"
                   width="160"
                   height="93"
                   :src="require('@/assets/eddie-sign.svg')" />
          <div class="landing-proof__sign-info">執行長: Eddie</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppImg from '@/components/AppImg'
import ProofRight from '@/components/ProofRight'
import ProofWrong from '@/components/ProofWrong'
import { bgProof } from '@/consts/img'
import { computed, ref, watch, onBeforeUnmount } from '@/utils'
import useWindowScroll from '@/composables/useWindowScroll'

const pop = ref(false)
const { scrolling, unsubscribe } = useWindowScroll()
const logoClass = computed(() => ({
  'landing-proof__logo-box--pop': pop.value
}))
let isIntersecting = false
const handleVisibilityChange = entry => {
  isIntersecting = entry.isIntersecting
  if (! isIntersecting) {
    pop.value = false
  }
}
watch(scrolling, () => {
  if ((! scrolling.value) && isIntersecting) {
    pop.value = true
  }
})
onBeforeUnmount(() => unsubscribe())
</script>

<style lang="scss">
.landing-proof__logo img {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.landing-proof {
  margin-top: -40px;
  h3 {
    line-height: 1.7;
  }
}
.landing-proof__hero {
  position: relative;
}
.landing-proof__logo-box {
  width: 244px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -50px;
  &.landing-proof__logo-box--pop .landing-proof__logo {
    transform: scale(1);
  }
}
.landing-proof__logo {
  transition: .3s transform;
  transform: scale(0);
  border: 1px solid $color-primary;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
  border-radius: 8px;
  background: #fff;
  padding: .5em;
  @include flex-center;
  @include size(100px);
}
.landing-proof__bg {
  background: #000;
  @include absolute-center;
  @include flex-center;
}
.landing-proof__hero-content {
  position: relative;
  background: rgba(0, 0, 0, .72);
  color: #fff;
  padding: 5.1em 0 4.6em;
  text-align: center;
}
.landing-proof__desc {
  padding-top: 1em;
  border-top: 1px solid #fff;
  width: fit-content;
  margin: 1em auto 0;
  padding-left: 1em;
  padding-right: 1em;
  &:after {
    clear: both;
  }
}
.landing-proof__list {
  text-align: center;
}
.landing-proof__how {
  background: #fff;
  padding-top: 4.2em;
  padding-bottom: 3em;
}
.landing-proof__eddie-statement {
  margin-top: 2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-size: .875rem;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
.landing-proof__sign-box {
  float: right;
  margin-top: 2em;
}
.landing-proof__sign {
  margin-right: 2em;
}
.landing-proof__sign-info {
  text-align: right;
}
</style>
