import FeatureShopPane from '@/components/FeatureShopPane'
import FeatureLogisticPane from '@/components/FeatureLogisticPane'
import FeatureProductPane from '@/components/FeatureProductPane'
import FeatureOrderPane from '@/components/FeatureOrderPane'
import FeatureCustomerPane from '@/components/FeatureCustomerPane'
import FeatureInvoicePane from '@/components/FeatureInvoicePane'
import FeatureMarketingPane from '@/components/FeatureMarketingPane'
import FeatureMicrositePane from '@/components/FeatureMicrositePane'
import FeatureAnalysisPane from '@/components/FeatureAnalysisPane'
import { extend } from '@/utils'

export const FEATURE_ITEMS = [
  { title: '建立商店', tag: FeatureShopPane },
  { title: '訂購商品', tag: FeatureProductPane },
  { title: '金流 / 物流整合', tag: FeatureLogisticPane },
  { title: '訂單管理', tag: FeatureOrderPane },
  { title: '顧客管理', tag: FeatureCustomerPane },
  { title: '開立發票', tag: FeatureInvoicePane },
  { title: '行銷工具整合', tag: FeatureMarketingPane },
  { title: '打造微官網', tag: FeatureMicrositePane },
  { title: '營收數據分析', tag: FeatureAnalysisPane }
].map((row, i) => {
  const key = `feature-item-${i}`
  return extend({}, row, { key })
})
