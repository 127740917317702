<template>
  <div class="triangle-right" />
</template>

<style lang="scss" scoped>
.triangle-right {
  width: 8px;
  height: 4px;
  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: currentcolor;
  border-right: 4px solid transparent;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}
</style>
