import { isValidCompanyCode } from '@/utils'
import Validator from '@/models/Validator'

export const company_name = v => {
  return Validator.value(v)
    .required('必須填寫公司名稱')
}

export const company_code = v => {
  return Validator.value(v)
    .required('必須填寫統一編號')
    .custom(() => {
      if (! isValidCompanyCode(v)) {
        return '這不是正確的統一編號'
      }
    })
}

export const company_address = v => {
  return Validator.value(v)
    .required('必須填寫公司地址')
}

export const warehouse_address = v => {
  return Validator.value(v)
    .required('必須填寫退貨地址')
}

export const brand_name = v => {
  return Validator.value(v)
    .required('必須填寫品牌名稱')
}

export const contact_name = v => {
  return Validator.value(v)
    .required('必須填寫聯絡人姓名')
}

export const contact_mobile = v => {
  return Validator.value(v)
    .required('必須填寫聯絡人電話')
}

export const contact_email = v => {
  return Validator.value(v)
    .email('請填寫正確的電子郵件地址')
    .required('必須填寫註冊信箱')
}

export const monthly_shippments_level_cd = v => {
  return Validator.value(v)
    .required('必須選擇每月寄件數量')
}
