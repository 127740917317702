import {
  Subject,
  debounceTime,
  fromEvent,
  throttleTime,
  ref
} from '@/utils'

export default function useWindowScroll() {

  const scrollTop = ref(document.documentElement.scrollTop)
  const scrolling = ref(false)

  const subject = new Subject()
  const scrollingSubscription = subject.pipe(debounceTime(300))
    .subscribe(value => {
      scrolling.value = value
    })

  const windowScrollSubscription = fromEvent(window, 'scroll')
    .pipe(throttleTime(30))
    .subscribe(() => {
      scrollTop.value = document.documentElement.scrollTop
      scrolling.value = true
      subject.next(false)
    })

  const unsubscribe = () => {
    scrollingSubscription.unsubscribe()
    windowScrollSubscription.unsubscribe()
  }
  return { scrollTop, scrolling, unsubscribe }
}
