import { scrollToElem } from '@/utils'

export default {
  mounted(el) {
    if (('#' + el.id) === window.location.hash) {
      // This setTimeout is needed for iphone chrome...
      setTimeout(() => scrollToElem(el), 100)
    }
  }
}
