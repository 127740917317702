<template>
  <button class="burger-btn" :class="classname" :aria-label="ariaLabel">
    <span aria-hidden="true" :style="{ background: color }" />
  </button>
</template>

<script setup>
import { defineProps, computed } from '@/utils'

const props = defineProps({
  color: {
    type: String,
    required: true
  },
  cross: Boolean,
})

const classname = computed(() => ({
  'burger-btn--cross': props.cross
}))

const ariaLabel = computed(() => props.cross ? '關閉選單' : '打開選單')
</script>

<style lang="scss" scoped>
.burger-btn {
  position: relative;
  border: 0;
  background: transparent;
  @include size(48px);
  span,
  span:before,
  span:after {
    transition: .3s all;
    border-radius: 4px;
    position: absolute;
    width: 22px;
    height: 4px;
    left: 0;
    right: 0;
  }
  span:before,
  span:after {
    content: '';
    background: inherit;
  }
  span {
    top: -16px;
    bottom: 0;
    margin: auto;
  }
  span:before {
    top: 8px;
  }
  span:after {
    bottom: -16px;
  }
  &.burger-btn--cross {
    span {
      transform: translateY(8px) rotate(45deg);
    }
    span:before {
      background: transparent !important;
    }
    span:after {
      transform: translateY(-16px) rotate(-90deg);
    }
  }
}
</style>
