import { comma, roundX } from '../utils'

const PRICING_LV1 = { lv: 1, text: '級距一', rate: 0.02 }
const PRICING_LV2 = { lv: 2, text: '級距二', rate: 0.015 }
const PRICING_LV3 = { lv: 3, text: '級距三', rate: 0.01 }
const PRICING_LV4 = { lv: 4, text: '級距四', rate: 0.005 }

export const PRICING_LVS = [
  PRICING_LV1,
  PRICING_LV2,
  PRICING_LV3,
  PRICING_LV4
]

// pricing logic reference: billing/calculate.rb
export const getPricingLvs = v => {
  if (v <= 500000) {
    return [
      { ...PRICING_LV1, value: v, fee: (v * PRICING_LV1.rate) }
    ]
  }
  if (v <= 3000000) {
    return [
      { ...PRICING_LV1, value: 500000, fee: (500000 * PRICING_LV1.rate) },
      { ...PRICING_LV2, value: (v - 500000), fee: ((v - 500000) * PRICING_LV2.rate) }
    ]
  }
  if (v <= 10000000) {
    return [
      { ...PRICING_LV1, value: 500000, fee: (500000 * PRICING_LV1.rate) },
      { ...PRICING_LV2, value: 2500000, fee: (2500000 * PRICING_LV2.rate) },
      { ...PRICING_LV3, value: (v - 3000000), fee: ((v - 3000000) * PRICING_LV3.rate) }
    ]
  }
  return [
    { ...PRICING_LV1, value: 500000, fee: (500000 * PRICING_LV1.rate) },
    { ...PRICING_LV2, value: 2500000, fee: (2500000 * PRICING_LV2.rate) },
    { ...PRICING_LV3, value: 7000000, fee: (7000000 * PRICING_LV3.rate) },
    { ...PRICING_LV4, value: (v - 10000000), fee: ((v - 10000000) * PRICING_LV4.rate) }
  ]
}

export const toTenThousand = v => (v === 0) ? '0' : `${comma(roundX(v / 10000))} 萬`

export const PRICING_LABELS = [10000, 500000, 3000000, 10000000].map(value => ({
  text: toTenThousand(value),
  value
}))
