<template>
  <div class="xdelivery-comparison">
    <h3 class="text-xdelivery text-center">經過我們實測 ..</h3>
    <table class="comparison-table comparison-table--xdelivery">
      <thead>
        <tr>
          <th>優化前 (其他物流)</th>
          <th></th>
          <th>優化後 (火箭快遞)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>12,000 件</td>
          <td>出貨總件數</td>
          <td>12,000 件</td>
        </tr>
        <tr>
          <td>平均約 50 元 / 件</td>
          <td>運費</td>
          <td>平均約 43 元 / 件</td>
        </tr>
        <tr>
          <td class="text-left">包裝用大紙箱 61 元<br>( 約可放6-15 件 )<br>送至物流倉庫<br>運費 135 元<br>(平均每件成本多 16 元)</td>
          <td>其他費用</td>
          <td class="text-left">配合上收服務，總倉直接到倉庫收件，每件 7 元。</td>
        </tr>
        <tr>
          <td class="text-left">需要一個人力專門把小紙箱、放到大紙箱裡面。</td>
          <td>其他成本</td>
          <td calss="text-left">無</td>
        </tr>
        <tr>
          <td>792,000 / 月<br>外加一個人力成本</td>
          <td>總計</td>
          <td>600,000 / 月</td>
        </tr>
      </tbody>
    </table>
    <div class="xdelivery-comparison__note text-xdelivery">換了一個作法，一個月省了 「 192,000 元 」 和 「 一個封箱人力 」 其實營運有非常多的隱藏成本，有大大小小能夠優化的流程、細節、費用 。</div>
  </div>
</template>

<style lang="scss" scoped>
.xdelivery-comparison {
  margin-top: -1px;
  padding-top: 1.5em;
  h3 {
    margin-bottom: .7em;
  }
}
.xdelivery-comparison__note {
  margin-top: 2em;
  line-height: 1.8;
  font-size: .875rem;
}
</style>
