<template>
  <footer class="app-footer">

    <div class="app-footer__page-links">
      <div>
        <router-link to="/features">功能介紹</router-link>
        <router-link to="/case-studies">成功案例</router-link>
        <router-link to="/pricing">收費方式</router-link>
      </div>
      <div>
        <router-link to="/contact">我想開店</router-link>
        <a @click.stop.prevent="showIntercomPopup(INTERCOM_TEXT)">
          聯絡我們
          <app-loader v-if="loading && (clickedType === INTERCOM_TEXT)"
                      class="app-footer__anchor-loader" />
        </a>
        <router-link to="/faqs">常見問題</router-link>
      </div>
    </div>

    <div class="app-footer__links">
      <a class="app-footer__icon" href="https://zh-tw.facebook.com/speeding.rocket/">
        <app-icon name="fb" />
        <span class="sr-only">一頁商店官方臉書粉絲專頁</span>
      </a>
      <button class="app-footer__icon"
              @click.stop.prevent="showIntercomPopup(INTERCOM_LOGO)">
        <app-loader v-if="loading && (clickedType === INTERCOM_LOGO)" />
        <app-icon v-else name="headset" />
        <span class="sr-only">intercom 客服連結</span>
      </button>
      <a class="app-footer__icon" href="mailto:support@super-landing.com">
        <app-icon name="envelop" />
        <span class="sr-only">寄信到 support@super-landing.com</span>
      </a>
    </div>

    <div class="app-footer__customer-service-info text-primary">客服時間為每週一至五上午 11 點至晚上 8 點<br> ( 每週六、日及例假日休息 ）</div>

    <div class="app-footer__copyright">©{{ year }} 光速火箭股份有限公司 統一編號: 65279724</div>
  </footer>
</template>

<script setup>
import AppIcon from '@/components/AppIcon'
import AppLoader from '@/components/AppLoader'
import useIntercom from '@/composables/useIntercom'
import { ref } from '@/utils'

const INTERCOM_TEXT = 'INTERCOM_TEXT'
const INTERCOM_LOGO = 'INTERCOM_LOGO'

const clickedType = ref('')
const year = new Date().getFullYear()

const { initIntercom, showIntercom, loading } = useIntercom()

const showIntercomPopup = type => {
  clickedType.value = type
  showIntercom()
}
initIntercom()
</script>

<style lang="scss">
.app-footer__anchor-loader {
  position: relative;
  margin-left: .4em;
  > div {
    border-width: 2px;
    border-color: $color-primary transparent transparent transparent;
  }
  @include size(16px);
}
</style>

<style lang="scss" scoped>
.app-footer {
  padding-top: 2em;
  padding-bottom: 2em;
  background: $bg-footer;
}
.app-footer__pages {
  display: flex;
  > div {
    flex-grow: 1;
  }
  > div:first-child {
    border-right: 1px solid $color-primary;
  }
  a {
    text-align: center;
    display: block;
  }
}
.app-footer__copyright {
  color: #484747;
  font-size: 12px;
  text-align: center;
  margin-top: 2em;
}
.app-footer__page-links {
  display: flex;
  justify-content: space-around;
  font-size: .875rem;
  > div:first-child {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      display: block;
      background: $color-primary;
      width: 2px;
      height: 80px;
      margin-top: auto;
      margin-bottom: auto;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  > div {
    flex-grow: 1;
  }
  a {
    padding-top: .3em;
    padding-bottom: .3em;
    @include flex-center;
    text-align: center;
  }
}
.app-footer__links {
  margin: 2em auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}
.app-footer__icon {
  position: relative;
  background-color: $color-primary;
  color: #fff;
  border: 0;
  border-radius: 50%;
  @include flex-center;
  @include size(50px);
  .icon-fb {
    font-size: 26px;
  }
  .icon-headset {
    font-size: 26px;
  }
  .icon-envelop {
    font-size: 19px;
    font-weight: 900;
  }
}
.app-footer__logo-f {
  width: 14px;
  height: auto;
}
.app-footer__logo-headphone {
  width: 30px;
  height: auto;
}
.app-footer__logo-envelop {
  width: 28px;
  height: auto;
}
.app-footer__customer-service-info {
  text-align: center;
  font-size: .875rem;
  line-height: 1.8;
}
</style>
