
export const bgProof = {
  src: require('@/assets/bg-proof.jpg'),
  srcset: {
    '1x': require('@/assets/bg-proof.jpg'),
    '2x': require('@/assets/bg-proof@2x.jpg'),
    '3x': require('@/assets/bg-proof@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/bg-proof.avif'),
    '2x': require('@/assets/bg-proof@2x.avif'),
    '3x': require('@/assets/bg-proof@3x.avif')
  },
  webp: {
    '1x': require('@/assets/bg-proof.webp'),
    '2x': require('@/assets/bg-proof@2x.webp'),
    '3x': require('@/assets/bg-proof@3x.webp')
  }
}

export const responsiveShareco = {
  src: require('@/assets/responsive-shareco.png'),
  srcset: {
    '1x': require('@/assets/responsive-shareco.png'),
    '2x': require('@/assets/responsive-shareco@2x.png'),
    '3x': require('@/assets/responsive-shareco@3x.png')
  },
  avif: {
    '1x': require('@/assets/responsive-shareco.avif'),
    '2x': require('@/assets/responsive-shareco@2x.avif'),
    '3x': require('@/assets/responsive-shareco@3x.avif')
  },
  webp: {
    '1x': require('@/assets/responsive-shareco.webp'),
    '2x': require('@/assets/responsive-shareco@2x.webp'),
    '3x': require('@/assets/responsive-shareco@3x.webp')
  }
}

export const slideShareco = {
  src: require('@/assets/slide-shareco.jpg'),
  srcset: {
    '1x': require('@/assets/slide-shareco.jpg'),
    '2x': require('@/assets/slide-shareco@2x.jpg'),
    '3x': require('@/assets/slide-shareco@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/slide-shareco.avif'),
    '2x': require('@/assets/slide-shareco@2x.avif'),
    '3x': require('@/assets/slide-shareco@3x.avif')
  },
  webp: {
    '1x': require('@/assets/slide-shareco.webp'),
    '2x': require('@/assets/slide-shareco@2x.webp'),
    '3x': require('@/assets/slide-shareco@3x.webp')
  }
}

export const slideMurfeeli = {
  src: require('@/assets/slide-murfeeli.jpg'),
  srcset: {
    '1x': require('@/assets/slide-murfeeli.jpg'),
    '2x': require('@/assets/slide-murfeeli@2x.jpg'),
    '3x': require('@/assets/slide-murfeeli@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/slide-murfeeli.avif'),
    '2x': require('@/assets/slide-murfeeli@2x.avif'),
    '3x': require('@/assets/slide-murfeeli@3x.avif')
  },
  webp: {
    '1x': require('@/assets/slide-murfeeli.webp'),
    '2x': require('@/assets/slide-murfeeli@2x.webp'),
    '3x': require('@/assets/slide-murfeeli@3x.webp')
  }
}

export const slideKp = {
  src: require('@/assets/slide-kp.jpg'),
  srcset: {
    '1x': require('@/assets/slide-kp.jpg'),
    '2x': require('@/assets/slide-kp@2x.jpg'),
    '3x': require('@/assets/slide-kp@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/slide-kp.avif'),
    '2x': require('@/assets/slide-kp@2x.avif'),
    '3x': require('@/assets/slide-kp@3x.avif')
  },
  webp: {
    '1x': require('@/assets/slide-kp.webp'),
    '2x': require('@/assets/slide-kp@2x.webp'),
    '3x': require('@/assets/slide-kp@3x.webp')
  }
}

export const rectShareco = {
  src: require('@/assets/rect-shareco.jpg'),
  srcset: {
    '1x': require('@/assets/rect-shareco.jpg'),
    '2x': require('@/assets/rect-shareco@2x.jpg'),
    '3x': require('@/assets/rect-shareco@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-shareco.avif'),
    '2x': require('@/assets/rect-shareco@2x.avif'),
    '3x': require('@/assets/rect-shareco@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-shareco.webp'),
    '2x': require('@/assets/rect-shareco@2x.webp'),
    '3x': require('@/assets/rect-shareco@3x.webp')
  }
}

export const rectKp = {
  src: require('@/assets/rect-kp.jpg'),
  srcset: {
    '1x': require('@/assets/rect-kp.jpg'),
    '2x': require('@/assets/rect-kp@2x.jpg'),
    '3x': require('@/assets/rect-kp@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-kp.avif'),
    '2x': require('@/assets/rect-kp@2x.avif'),
    '3x': require('@/assets/rect-kp@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-kp.webp'),
    '2x': require('@/assets/rect-kp@2x.webp'),
    '3x': require('@/assets/rect-kp@3x.webp')
  }
}

export const rectRelove = {
  src: require('@/assets/rect-relove.jpg'),
  srcset: {
    '1x': require('@/assets/rect-relove.jpg'),
    '2x': require('@/assets/rect-relove@2x.jpg'),
    '3x': require('@/assets/rect-relove@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-relove.avif'),
    '2x': require('@/assets/rect-relove@2x.avif'),
    '3x': require('@/assets/rect-relove@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-relove.webp'),
    '2x': require('@/assets/rect-relove@2x.webp'),
    '3x': require('@/assets/rect-relove@3x.webp')
  }
}

export const rectHj = {
  src: require('@/assets/rect-hj.jpg'),
  srcset: {
    '1x': require('@/assets/rect-hj.jpg'),
    '2x': require('@/assets/rect-hj@2x.jpg'),
    '3x': require('@/assets/rect-hj@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-hj.avif'),
    '2x': require('@/assets/rect-hj@2x.avif'),
    '3x': require('@/assets/rect-hj@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-hj.webp'),
    '2x': require('@/assets/rect-hj@2x.webp'),
    '3x': require('@/assets/rect-hj@3x.webp')
  }
}

export const rectLaopengyu = {
  src: require('@/assets/rect-laopengyu.jpg'),
  srcset: {
    '1x': require('@/assets/rect-laopengyu.jpg'),
    '2x': require('@/assets/rect-laopengyu@2x.jpg'),
    '3x': require('@/assets/rect-laopengyu@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-laopengyu.avif'),
    '2x': require('@/assets/rect-laopengyu@2x.avif'),
    '3x': require('@/assets/rect-laopengyu@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-laopengyu.webp'),
    '2x': require('@/assets/rect-laopengyu@2x.webp'),
    '3x': require('@/assets/rect-laopengyu@3x.webp')
  }
}

export const rectKafen = {
  src: require('@/assets/rect-kafen.jpg'),
  srcset: {
    '1x': require('@/assets/rect-kafen.jpg'),
    '2x': require('@/assets/rect-kafen@2x.jpg'),
    '3x': require('@/assets/rect-kafen@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-kafen.avif'),
    '2x': require('@/assets/rect-kafen@2x.avif'),
    '3x': require('@/assets/rect-kafen@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-kafen.webp'),
    '2x': require('@/assets/rect-kafen@2x.webp'),
    '3x': require('@/assets/rect-kafen@3x.webp')
  }
}

export const rectMurfeeli = {
  src: require('@/assets/rect-murfeeli.jpg'),
  srcset: {
    '1x': require('@/assets/rect-murfeeli.jpg'),
    '2x': require('@/assets/rect-murfeeli@2x.jpg'),
    '3x': require('@/assets/rect-murfeeli@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-murfeeli.avif'),
    '2x': require('@/assets/rect-murfeeli@2x.avif'),
    '3x': require('@/assets/rect-murfeeli@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-murfeeli.webp'),
    '2x': require('@/assets/rect-murfeeli@2x.webp'),
    '3x': require('@/assets/rect-murfeeli@3x.webp')
  }
}

export const rectJkao = {
  src: require('@/assets/rect-jkao.jpg'),
  srcset: {
    '1x': require('@/assets/rect-jkao.jpg'),
    '2x': require('@/assets/rect-jkao@2x.jpg'),
    '3x': require('@/assets/rect-jkao@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-jkao.avif'),
    '2x': require('@/assets/rect-jkao@2x.avif'),
    '3x': require('@/assets/rect-jkao@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-jkao.webp'),
    '2x': require('@/assets/rect-jkao@2x.webp'),
    '3x': require('@/assets/rect-jkao@3x.webp')
  }
}

export const rectYk = {
  src: require('@/assets/rect-yk.jpg'),
  srcset: {
    '1x': require('@/assets/rect-yk.jpg'),
    '2x': require('@/assets/rect-yk@2x.jpg'),
    '3x': require('@/assets/rect-yk@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-yk.avif'),
    '2x': require('@/assets/rect-yk@2x.avif'),
    '3x': require('@/assets/rect-yk@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-yk.webp'),
    '2x': require('@/assets/rect-yk@2x.webp'),
    '3x': require('@/assets/rect-yk@3x.webp')
  }
}

export const rectMastersk = {
  src: require('@/assets/rect-mastersk.jpg'),
  srcset: {
    '1x': require('@/assets/rect-mastersk.jpg'),
    '2x': require('@/assets/rect-mastersk@2x.jpg'),
    '3x': require('@/assets/rect-mastersk@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/rect-mastersk.avif'),
    '2x': require('@/assets/rect-mastersk@2x.avif'),
    '3x': require('@/assets/rect-mastersk@3x.avif')
  },
  webp: {
    '1x': require('@/assets/rect-mastersk.webp'),
    '2x': require('@/assets/rect-mastersk@2x.webp'),
    '3x': require('@/assets/rect-mastersk@3x.webp')
  }
}

export const contactMobiles = {
  src: require('@/assets/contact-mobiles.png'),
  srcset: {
    '1x': require('@/assets/contact-mobiles.png'),
    '2x': require('@/assets/contact-mobiles@2x.png'),
    '3x': require('@/assets/contact-mobiles@3x.png')
  },
  avif: {
    '1x': require('@/assets/contact-mobiles.avif'),
    '2x': require('@/assets/contact-mobiles@2x.avif'),
    '3x': require('@/assets/contact-mobiles@3x.avif')
  },
  webp: {
    '1x': require('@/assets/contact-mobiles.webp'),
    '2x': require('@/assets/contact-mobiles@2x.webp'),
    '3x': require('@/assets/contact-mobiles@3x.webp')
  }
}

export const brandMobileDemoShareco = {
  src: require('@/assets/brand-mobile-demo-shareco.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-shareco.jpg'),
    '2x': require('@/assets/brand-mobile-demo-shareco@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-shareco@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-shareco.avif'),
    '2x': require('@/assets/brand-mobile-demo-shareco@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-shareco@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-shareco.webp'),
    '2x': require('@/assets/brand-mobile-demo-shareco@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-shareco@3x.webp')
  }
}

export const brandMobileDemoKp = {
  src: require('@/assets/brand-mobile-demo-kp.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-kp.jpg'),
    '2x': require('@/assets/brand-mobile-demo-kp@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-kp@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-kp.avif'),
    '2x': require('@/assets/brand-mobile-demo-kp@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-kp@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-kp.webp'),
    '2x': require('@/assets/brand-mobile-demo-kp@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-kp@3x.webp')
  }
}

export const brandMobileDemoRelove = {
  src: require('@/assets/brand-mobile-demo-relove.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-relove.jpg'),
    '2x': require('@/assets/brand-mobile-demo-relove@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-relove@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-relove.avif'),
    '2x': require('@/assets/brand-mobile-demo-relove@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-relove@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-relove.webp'),
    '2x': require('@/assets/brand-mobile-demo-relove@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-relove@3x.webp')
  }
}

export const brandMobileDemoHj = {
  src: require('@/assets/brand-mobile-demo-hj.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-hj.jpg'),
    '2x': require('@/assets/brand-mobile-demo-hj@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-hj@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-hj.avif'),
    '2x': require('@/assets/brand-mobile-demo-hj@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-hj@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-hj.webp'),
    '2x': require('@/assets/brand-mobile-demo-hj@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-hj@3x.webp')
  }
}

export const brandMobileDemoLao = {
  src: require('@/assets/brand-mobile-demo-lao.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-lao.jpg'),
    '2x': require('@/assets/brand-mobile-demo-lao@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-lao@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-lao.avif'),
    '2x': require('@/assets/brand-mobile-demo-lao@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-lao@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-lao.webp'),
    '2x': require('@/assets/brand-mobile-demo-lao@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-lao@3x.webp')
  }
}

export const brandMobileDemoKafen = {
  src: require('@/assets/brand-mobile-demo-kafen.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-kafen.jpg'),
    '2x': require('@/assets/brand-mobile-demo-kafen@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-kafen@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-kafen.avif'),
    '2x': require('@/assets/brand-mobile-demo-kafen@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-kafen@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-kafen.webp'),
    '2x': require('@/assets/brand-mobile-demo-kafen@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-kafen@3x.webp')
  }
}

export const brandMobileDemoMurfeeli = {
  src: require('@/assets/brand-mobile-demo-murfeeli.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-murfeeli.jpg'),
    '2x': require('@/assets/brand-mobile-demo-murfeeli@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-murfeeli@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-murfeeli.avif'),
    '2x': require('@/assets/brand-mobile-demo-murfeeli@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-murfeeli@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-murfeeli.webp'),
    '2x': require('@/assets/brand-mobile-demo-murfeeli@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-murfeeli@3x.webp')
  }
}

export const brandMobileDemoJkao = {
  src: require('@/assets/brand-mobile-demo-jkao.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-jkao.jpg'),
    '2x': require('@/assets/brand-mobile-demo-jkao@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-jkao@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-jkao.avif'),
    '2x': require('@/assets/brand-mobile-demo-jkao@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-jkao@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-jkao.webp'),
    '2x': require('@/assets/brand-mobile-demo-jkao@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-jkao@3x.webp')
  }
}

export const brandMobileDemoYk = {
  src: require('@/assets/brand-mobile-demo-yk.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-yk.jpg'),
    '2x': require('@/assets/brand-mobile-demo-yk@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-yk@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-yk.avif'),
    '2x': require('@/assets/brand-mobile-demo-yk@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-yk@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-yk.webp'),
    '2x': require('@/assets/brand-mobile-demo-yk@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-yk@3x.webp')
  }
}

export const brandMobileDemoMastersk = {
  src: require('@/assets/brand-mobile-demo-mastersk.jpg'),
  srcset: {
    '1x': require('@/assets/brand-mobile-demo-mastersk.jpg'),
    '2x': require('@/assets/brand-mobile-demo-mastersk@2x.jpg'),
    '3x': require('@/assets/brand-mobile-demo-mastersk@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-mobile-demo-mastersk.avif'),
    '2x': require('@/assets/brand-mobile-demo-mastersk@2x.avif'),
    '3x': require('@/assets/brand-mobile-demo-mastersk@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-mobile-demo-mastersk.webp'),
    '2x': require('@/assets/brand-mobile-demo-mastersk@2x.webp'),
    '3x': require('@/assets/brand-mobile-demo-mastersk@3x.webp')
  }
}

export const brandBgShareco = {
  src: require('@/assets/brand-bg-shareco.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-shareco.jpg'),
    '2x': require('@/assets/brand-bg-shareco@2x.jpg'),
    '3x': require('@/assets/brand-bg-shareco@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-shareco.avif'),
    '2x': require('@/assets/brand-bg-shareco@2x.avif'),
    '3x': require('@/assets/brand-bg-shareco@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-shareco.webp'),
    '2x': require('@/assets/brand-bg-shareco@2x.webp'),
    '3x': require('@/assets/brand-bg-shareco@3x.webp')
  }
}

export const brandBgKp = {
  src: require('@/assets/brand-bg-kp.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-kp.jpg'),
    '2x': require('@/assets/brand-bg-kp@2x.jpg'),
    '3x': require('@/assets/brand-bg-kp@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-kp.avif'),
    '2x': require('@/assets/brand-bg-kp@2x.avif'),
    '3x': require('@/assets/brand-bg-kp@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-kp.webp'),
    '2x': require('@/assets/brand-bg-kp@2x.webp'),
    '3x': require('@/assets/brand-bg-kp@3x.webp')
  }
}

export const brandBgRelove = {
  src: require('@/assets/brand-bg-relove.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-relove.jpg'),
    '2x': require('@/assets/brand-bg-relove@2x.jpg'),
    '3x': require('@/assets/brand-bg-relove@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-relove.avif'),
    '2x': require('@/assets/brand-bg-relove@2x.avif'),
    '3x': require('@/assets/brand-bg-relove@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-relove.webp'),
    '2x': require('@/assets/brand-bg-relove@2x.webp'),
    '3x': require('@/assets/brand-bg-relove@3x.webp')
  }
}

export const brandBgHj = {
  src: require('@/assets/brand-bg-hj.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-hj.jpg'),
    '2x': require('@/assets/brand-bg-hj@2x.jpg'),
    '3x': require('@/assets/brand-bg-hj@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-hj.avif'),
    '2x': require('@/assets/brand-bg-hj@2x.avif'),
    '3x': require('@/assets/brand-bg-hj@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-hj.webp'),
    '2x': require('@/assets/brand-bg-hj@2x.webp'),
    '3x': require('@/assets/brand-bg-hj@3x.webp')
  }
}

export const brandBgLao = {
  src: require('@/assets/brand-bg-lao.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-lao.jpg'),
    '2x': require('@/assets/brand-bg-lao@2x.jpg'),
    '3x': require('@/assets/brand-bg-lao@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-lao.avif'),
    '2x': require('@/assets/brand-bg-lao@2x.avif'),
    '3x': require('@/assets/brand-bg-lao@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-lao.webp'),
    '2x': require('@/assets/brand-bg-lao@2x.webp'),
    '3x': require('@/assets/brand-bg-lao@3x.webp')
  }
}

export const brandBgKafen = {
  src: require('@/assets/brand-bg-kafen.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-kafen.jpg'),
    '2x': require('@/assets/brand-bg-kafen@2x.jpg'),
    '3x': require('@/assets/brand-bg-kafen@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-kafen.avif'),
    '2x': require('@/assets/brand-bg-kafen@2x.avif'),
    '3x': require('@/assets/brand-bg-kafen@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-kafen.webp'),
    '2x': require('@/assets/brand-bg-kafen@2x.webp'),
    '3x': require('@/assets/brand-bg-kafen@3x.webp')
  }
}

export const brandBgMurfeeli = {
  src: require('@/assets/brand-bg-murfeeli.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-murfeeli.jpg'),
    '2x': require('@/assets/brand-bg-murfeeli@2x.jpg'),
    '3x': require('@/assets/brand-bg-murfeeli@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-murfeeli.avif'),
    '2x': require('@/assets/brand-bg-murfeeli@2x.avif'),
    '3x': require('@/assets/brand-bg-murfeeli@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-murfeeli.webp'),
    '2x': require('@/assets/brand-bg-murfeeli@2x.webp'),
    '3x': require('@/assets/brand-bg-murfeeli@3x.webp')
  }
}

export const brandBgJkao = {
  src: require('@/assets/brand-bg-jkao.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-jkao.jpg'),
    '2x': require('@/assets/brand-bg-jkao@2x.jpg'),
    '3x': require('@/assets/brand-bg-jkao@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-jkao.avif'),
    '2x': require('@/assets/brand-bg-jkao@2x.avif'),
    '3x': require('@/assets/brand-bg-jkao@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-jkao.webp'),
    '2x': require('@/assets/brand-bg-jkao@2x.webp'),
    '3x': require('@/assets/brand-bg-jkao@3x.webp')
  }
}

export const brandBgYk = {
  src: require('@/assets/brand-bg-yk.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-yk.jpg'),
    '2x': require('@/assets/brand-bg-yk@2x.jpg'),
    '3x': require('@/assets/brand-bg-yk@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-yk.avif'),
    '2x': require('@/assets/brand-bg-yk@2x.avif'),
    '3x': require('@/assets/brand-bg-yk@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-yk.webp'),
    '2x': require('@/assets/brand-bg-yk@2x.webp'),
    '3x': require('@/assets/brand-bg-yk@3x.webp')
  }
}

export const brandBgMastersk = {
  src: require('@/assets/brand-bg-mastersk.jpg'),
  srcset: {
    '1x': require('@/assets/brand-bg-mastersk.jpg'),
    '2x': require('@/assets/brand-bg-mastersk@2x.jpg'),
    '3x': require('@/assets/brand-bg-mastersk@3x.jpg')
  },
  avif: {
    '1x': require('@/assets/brand-bg-mastersk.avif'),
    '2x': require('@/assets/brand-bg-mastersk@2x.avif'),
    '3x': require('@/assets/brand-bg-mastersk@3x.avif')
  },
  webp: {
    '1x': require('@/assets/brand-bg-mastersk.webp'),
    '2x': require('@/assets/brand-bg-mastersk@2x.webp'),
    '3x': require('@/assets/brand-bg-mastersk@3x.webp')
  }
}
