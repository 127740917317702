<template>
  <div class="pricing-calculator">
    <div class="pricing-calculator__title">月收單金額試算表</div>
    <div class="text-primary">月收單總金額</div>
    <range-input v-model="income"
                 :labels="PRICING_LABELS"
                 :min="0"
                 :max="13000000" />
    <pricing-table :value="income" />
  </div>
</template>

<script setup>
import PricingTable from '@/components/PricingTable'
import RangeInput from '@/components/RangeInput'
import { ref } from '@/utils'
import { PRICING_LABELS } from '~/official-common/consts/pricing'

const income = ref(500000)
</script>

<style lang="scss" scoped>
.pricing-calculator {
  margin-top: 3em;
  position: relative;
  border: 1px solid #ddd;
  padding: 1.5em .8em 1em;
  font-size: .875rem;
}
.pricing-calculator__title {
  padding-left: .3em;
  padding-right: .3em;
  display: block;
  width: fit-content;
  position: absolute;
  top: -14px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #a0a0a0;
  color: #fff;
}
.pricing-calculator__table {
  background: linear-gradient(-45deg, #f5e5f4, #e6e3f9);
  padding: 1em;
  margin-top: 2.4em;
  > table {
    width: 100%;
  }
}
.pricing-calculator__lv-text-cell {
  font-weight: 500;
}
.pricing-calculator__lv-value-cell {
  text-align: right;
}
.pricing-calculator__lv-subtotal-cell {
  text-align: right;
}
</style>
