<template>
  <div class="feature-item" :class="classname" @click="open = (! open)">
    <div class="feature-item__title">
      <slot name="title" />
    </div>
    <div class="feature-item__desc">
      <slot name="desc" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from '@/utils'

const open = ref(false)
const classname = computed(() => ({
  'feature-item--open': open.value
}))
</script>

<style lang="scss">
.feature-item__desc > div > picture > img {
  display: block;
  margin-left: 3.6em;
  margin-right: 3.6em;
  margin-bottom: 2em;
}
</style>

<style lang="scss" scoped>
$color-border: rgba(114, 74, 128, .4);
.feature-item {
  position: relative;
  margin-top: .4em;
  margin-bottom: .4em;
  transform: .3s all;
  &.feature-item--open {
    margin-left: 1em;
    margin-right: 1em;
    border-bottom: 1px solid $color-border;
    .feature-item__title {
      font-weight: 900;
      border-radius: 25px;
      background: $color-primary;
      color: #fff;
      padding-left: 1em;
      margin-left: 0;
      margin-right: 0;
      &:after {
        transform: rotate(225deg);
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        right: 30px;
      }
    }
    .feature-item__desc {
      display: block;
    }
  }
}
.feature-item__title {
  margin-left: 1.5em;
  margin-right: 1.5em;
  position: relative;
  padding: .7em 0;
  color: $color-primary;
  transition: .3s all;
  font-size: 1.2rem;
  font-weight: 900;
  border-bottom: 1px solid $color-border;
  &:after {
    transition: .3s all;
    position: absolute;
    right: 3px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(45deg);
    display: block;
    content: '';
    @include size(10px);
    border-right: 2px solid $color-primary;
    border-bottom: 2px solid $color-primary;
  }
}
.feature-item__desc {
  display: none;
  font-size: .875rem;
  color: #686868;
  line-height: 2;
  margin-top: 1.9em;
  padding-left: .5em;
  padding-right: .5em;
}
</style>
