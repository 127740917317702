<template>
  <div class="not-found-page">
    <h2 class="text-primary">很抱歉<br>找不到您要瀏覽的網頁 ⊙︿⊙</h2>
    <div class="text-primary">伺服器上找不到網址 {{ path }}<br>對應的資料</div>
    <app-btn class="not-found-page__btn"
             rect
             sm
             to="/">回首頁</app-btn>
    <app-img alt="一個長髮女生拿著放大鏡指向 404 的字樣"
             :src="require('@/assets/bg-404.svg')" />
  </div>
</template>

<script setup>
import AppBtn from '@/components/AppBtn'
import AppImg from '@/components/AppImg'

const path = location.pathname
</script>

<style lang="scss" scoped>
.not-found-page {
  background: linear-gradient(180deg, #e6e1ec, #fff);
  padding-top: 5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 2em;
  h2 {
    margin-bottom: .6em;
  }
}
.not-found-page__btn {
  margin-top: 1.4em;
  margin-bottom: 3em;
}
</style>
