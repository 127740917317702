<template>
  <div>
    <xdelivery-info />
    <xdelivery-why />
    <xdelivery-features />
    <xdelivery-form />
  </div>
</template>

<script setup>
import XdeliveryInfo from '@/components/XdeliveryInfo'
import XdeliveryWhy from '@/components/XdeliveryWhy'
import XdeliveryFeatures from '@/components/XdeliveryFeatures'
import XdeliveryForm from '@/components/XdeliveryForm'
import useAnchor from '@/composables/useAnchor'

useAnchor()
</script>
