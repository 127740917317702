<template>
  <div class="form-group">
    <form-label v-if="label"
                forId="id"
                :required="required">{{ label }}</form-label>
    <select class="form-control"
            :class="textareaClass"
            @input="event => emit('update:modelValue', event.target.value)">
      <option value="">請選擇寄件數量</option>
      <option value="0">尚未開始使用</option>
      <option value="1">100 件 ~ 1,000 件</option>
      <option value="2">1,000 件 ~ 5,000 件</option>
      <option value="3">5,000 件 ~ 10,000 件</option>
      <option value="4">10,000 件以上</option>
    </select>
    <form-errs :err="err" />
  </div>
</template>

<script setup>
import FormLabel from '@/components/FormLabel'
import FormErrs from '@/components/FormErrs'
import { computed, defineEmits, defineProps } from '@/utils'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  label: String,
  required: Boolean,
  err: [String, Array]
})

const textareaClass = computed(() => ({
  'form-control--danger': props.err
}))
</script>

<style lang="scss" scoped>
</style>
