<template>
  <div v-if="msgs.length > 0" class="form-errs">
    <div v-for="(msg, i) in msgs"
         class="form-errs__err"
         :key="i">{{ msg }}</div>
  </div>
</template>

<script setup>
import { computed, defineProps, isStr } from '@/utils'

const props = defineProps({
  err: [String, Array]
})

const msgs = computed(() => {
  const { err } = props
  if (isStr(err)) {
    return [err]
  }
  if (Array.isArray(err)) {
    return err
  }
  return []
})
</script>

<style lang="scss" scoped>
.form-errs {
  color: $color-danger;
}
</style>
