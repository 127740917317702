<template>
  <label class="form-label" :class="classname" :for="forId">
    <slot />
  </label>
</template>

<script setup>
import { defineProps, computed } from '@/utils'

const props = defineProps({
  forId: String,
  required: Boolean
})

const classname = computed(() => ({
  'form-label--required': props.required
}))
</script>

<style lang="scss" scoped>
.form-label {
  display: block;
}
.form-label.form-label--required:before {
  content: '*';
  color: $color-danger;
}
</style>
