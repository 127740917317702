<template>
  <div class="form-group">
    <form-label v-if="label"
                forId="id"
                :required="required">{{ label }}</form-label>
      <div class="form-control__wrap">
        <input class="form-control"
               type="text"
               :class="inputClass"
               :placeholder="placeholder"
               :inputmode="inputmode"
               @input="event => emit('update:modelValue', event.target.value)" />
        <app-icon v-if="err"
                  class="form-input__exclamation-icon"
                  name="exclamation" />
      </div>
    <form-errs :err="err" />
  </div>
</template>

<script setup>
import AppIcon from '@/components/AppIcon'
import FormLabel from '@/components/FormLabel'
import FormErrs from '@/components/FormErrs'
import { computed, defineEmits, defineProps } from '@/utils'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  inputmode: String,
  label: String,
  placeholder: String,
  required: Boolean,
  err: [String, Array]
})

const inputClass = computed(() => ({
  'form-control--danger': props.err
}))
</script>

<style lang="scss" scoped>
.form-control__wrap {
  position: relative;
}
.form-input__exclamation-icon {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  color: #fff;
  background: $color-danger;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  line-height: 26px;
  @include size(26px);
}
</style>
