export default function visibility() {
  if (! ('IntersectionObserver' in window)) {
    return {}
  }
  const map = new Map()
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const el = entry.target
      const binding = map.get(el)
      const fn = binding.value
      if (! fn) {
        return
      }
      fn(entry)
    })
  })
  return {
    mounted(el, binding) {
      map.set(el, binding)
      observer.observe(el)
    },
    beforeUnmount(el) {
      observer.unobserve(el)
      map.delete(el)
    }
  }
}
