<template>
  <div class="contact-form">
    <div class="contact-form__content">
      <div v-if="created" class="contact-form__info text-primary">您好，感謝您的申請！<br>
  您的資訊將由一頁商店審核小組進行確認，<br>審核流程約需 3-5 個工作天 (不含例假日)，如通過申請，我們會寄送 Email 通知您 ; 如未通過申請，恕不另行通知。<br>有任何問題，歡迎您於上班時間洽詢線上客服，謝謝！</div>
      <div v-else>
        <div class="contact-form__info text-primary">一般審核約需3-5個工作天(不含六日及例假日)，如果通過審核，系統會主動寄出登入通知；若未通過審核恕不另行通知，還請見諒！ </div>

        <div class="text-danger">* 號為必填欄位</div>
        <form @submit.stop.prevent="submit">
          <form-input v-model="form.contact_name"
                      label="品牌名 or 粉絲團 or 聯絡人"
                      placeholder="範例: 特質嚴選商店"
                      required
                      :ref="setRef('contact_name')"
                      :err="err.first('contact_name')" />

          <form-input v-model="form.contact_mobile"
                      label="聯絡電話"
                      placeholder="範例: 0912345678"
                      required
                      :ref="setRef('contact_mobile')"
                      :err="err.first('contact_mobile')" />

          <form-input v-model="form.contact_email"
                      label="連絡信箱"
                      placeholder="範例: eddie@gmail.com"
                      required
                      :ref="setRef('contact_email')"
                      :err="err.first('contact_email')" />

          <form-textarea v-model="form.products_describe"
                         label="你要賣的產品是"
                         required
                         placeholder="範例：吸塵器、洗髮精、3C用品"
                         :ref="setRef('products_describe')"
                         :err="err.first('products_describe')" />

          <form-input v-model="form.company_name"
                      label="公司名稱"
                      placeholder="範例: 光速火箭股份有限公司" />

          <form-input v-model="form.numbers_of_employees"
                      label="公司人數"
                      placeholder="範例: 4"
                      inputmode="numeric" />

          <form-input v-model="form.official_website_url"
                      placeholder="範例: https://www.domain.com"
                      label="現有賣場網站連結" />

          <div class="contact-form__hint text-primary">請您在送出申請前，確認已填寫所有欄位，以加快後續審核流程，謝謝您！</div>

          <app-btn class="contact-form__btn"
                   arrow
                   dark-gradient
                   block
                   :loading="loading">立即送出申請</app-btn>

          <div class="contact-form__hint text-primary">小提醒：一頁商店目前僅受理台灣地區用戶申請及使用，且並無提供出貨、廣告行銷或製作銷售頁的服務！</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppBtn from '@/components/AppBtn'
import FormInput from '@/components/FormInput'
import FormTextarea from '@/components/FormTextarea'
import useValidation from '@/composables/useValidation'
import { axios, get, reactive, ref } from '@/utils'
import * as validators from '@/validators/contact'

const created = ref(false)
const loading = ref(false)

const form = reactive({
  contact_name: '',
  contact_mobile: '',
  contact_email: '',
  products_describe: '',
  company_name: '',
  numbers_of_employees: 0,
  official_website_url: ''
})

const refs = ref({})
const { validate, err, setErrs, setRef } = useValidation({ form, validators, refs })

const submit = () => {
  const success = validate()
  if (! success) {
    return
  }
  loading.value = true
  axios.head('/csrf')
    .then(res => {
      const csrfToken = res.headers['x-csrf-token']
      const data = {
        authenticity_token: csrfToken,
        apply: form
      }
      axios.post('/applies.json', data)
        .then(() => {
          created.value = true
          if (window.fbq) {
            window.fbq('track', 'CompleteRegistration')
          }
        })
        .catch(err => {
          const fieldErrs = get(err, 'response.data.errors')
          if (fieldErrs) {
            setErrs(fieldErrs)
          }
        })
        .finally(() => loading.value = false)
    })
}
</script>

<style lang="scss" scoped>
.contact-form {
  background: linear-gradient(180deg, transparent, $bg-footer);
  margin-top: -40px;
}
.contact-form__content {
  border-radius: 40px;
  background: #fff;
  padding: 40px 1.5rem;
}
.contact-form__info {
  font-weight: 900;
}
.contact-form__hint {
  font-size: 13px;
}
.contact-form__btn {
  margin-top: 1em;
  margin-bottom: 1em;
  min-height: 42px;
}
</style>
