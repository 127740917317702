import {
  fromEvent,
  map,
  merge,
  parseEvent,
  ref,
  roundX,
  switchMap,
  takeUntil
} from '@/utils'

export default function useDrag(el, options = {}) {

  const pressed = options.pressed || ref(false)
  const left = ref(options.left || 0)
  const deltaX = options.deltaX || ref(0)
  const deltaY = options.deltaY || ref(0)

  const touchStart$ = fromEvent(el, 'touchstart', { passive: true })
  const touchMove$ = fromEvent(document, 'touchmove', { passive: false })
  const touchEnd$ = fromEvent(document, 'touchend', { passive: true })

  const mouseDown$ = fromEvent(el, 'mousedown')
  const mouseMove$ = fromEvent(document, 'mousemove')
  const mouseUp$ = fromEvent(document, 'mouseup')

  const down$ = merge(mouseDown$, touchStart$)
  const move$ = merge(mouseMove$, touchMove$)
  const up$ = merge(mouseUp$, touchEnd$)

  const upSubscription = up$.subscribe(() => {
    pressed.value = false
  })

  const downSubscription = down$.pipe(switchMap(down => {
    pressed.value = true
    const d = parseEvent(down)
    return move$.pipe(
      map(move => {
        const m = parseEvent(move)
        return {
          left: roundX(m.x),
          deltaX: roundX(m.x - d.x),
          deltaY: roundX(m.y - d.y)
        }
      }),
      takeUntil(up$)
    )
  }))
  .subscribe(value => {
    left.value = value.left
    deltaX.value = value.deltaX
    deltaY.value = value.deltaY
  })

  const unsubscribe = () => {
    downSubscription.unsubscribe()
    upSubscription.unsubscribe()
  }

  return { left, unsubscribe }
}
