<template>
  <div class="step-box" ref="root" :class="classname">
    <div class="step-box__content">
      <step-item step="1"
                 alt="銷售頁清單分別顯示在手機與桌機螢幕"
                 :src="require('@/assets/feature-shop.svg')"
                 :width="232"
                 :height="174"
                 @click="step = 1">
        <template #title>五分鐘建立銷售頁</template>
        <template #desc>擁有行銷經驗團隊設計，打造最懂電商需求的介面功能，輸入文字、上傳圖片，5 分鐘建立銷售頁。</template>
      </step-item>
      <step-item step="2"
                 alt="臉書圖示、使用者頭像資訊與一些追蹤碼"
                 :src="require('@/assets/feature-marketing.svg')"
                 @click="step = 2">
        <template #title>整合廣告追蹤工具</template>
        <template #desc>不懂程式碼也能輕鬆串接 FB 像素及 GA 追蹤編號， 完整紀錄消費者的購物行為，掌握廣告轉單成效。</template>
      </step-item>
      <step-item step="3"
                 alt="手機螢幕上有勾勾圖示、寄件訊息與購物資訊"
                 :src="require('@/assets/feature-product.svg')"
                 @click="step = 3">
        <template #title>快速下單零阻力</template>
        <template #desc>一頁商店資訊集中零干擾，能引導消費者專心瀏覽行銷內容，免註冊即可下單，提升 3-5 倍轉單率。</template>
      </step-item>
      <step-item step="4"
                 alt="桌機螢幕上顯示訂單畫面"
                 :src="require('@/assets/feature-order.svg')"
                 @click="step = 4">
        <template #title>智慧 & 靈活的訂單協作系統</template>
        <template #desc>把天天使用的訂單功能，打造為 SOP 營運系統，有效降低訂單人員出錯率，減少公司隱藏的耗損！</template>
      </step-item>
      <step-item step="5"
                 alt="錢字號、長條圖與物流貨車"
                 :src="require('@/assets/step5.svg')"
                 @click="step = 5">
        <template #title>支援台灣主流金流、物流服務</template>
        <template #desc>藍新、綠界；黑貓、新竹；超商貨到付款、信用卡、虛擬帳號！出貨、收款全部搞定 !</template>
      </step-item>
      <step-item step="6"
                 alt="開啟的信封袋與手機上的通知訊息"
                 :src="require('@/assets/feature-customer.svg')"
                 @click="step = 6">
        <template #title>強大 CRM 會員受眾管理</template>
        <template #desc>免登入下單也能做消費記錄管理，自訂回購週期，拉出名單後發送 Email、簡訊提醒回購，業績再多 20%！</template>
      </step-item>
    </div>
  </div>
</template>

<script setup>
import StepItem from '@/components/StepItem'
import useDrag from '@/composables/useDrag'
import { computed, onBeforeUnmount, onMounted, ref, watch } from '@/utils'

const root = ref(null)
const step = ref(1)
const stepTotal = 6
const deltaX = ref(0)
const pressed = ref(false)
const classname = computed(() => `step-box--step${step.value}`)
const goPrev = () => {
  if (1 < step.value) {
    step.value -= 1
  }
}
const goNext = () => {
  if (step.value < stepTotal) {
    step.value += 1
  }
}

watch(pressed, () => {
  if (pressed.value) {
    return
  }
  const x = deltaX.value

  if (Math.abs(x) < 5) {
    deltaX.value = 0
    return
  }
  const swipeLeft = x < 0
  const swipeRight = x > 0
  if (swipeRight) {
    goPrev()
  }
  if (swipeLeft) {
    goNext()
  }
  deltaX.value = 0
})

onMounted(() => {
  const { unsubscribe } = useDrag(root.value, { pressed, deltaX })
  onBeforeUnmount(() => unsubscribe())
})
</script>

<style lang="scss" scoped>
.step-box {
  overflow: hidden;
  padding-top: 1em;
  padding-bottom: 1em;
  @for $step from 1 to 7 {
    &.step-box--step#{$step} .step-box__content {
      transform: translateX(-#{($step-item-width + 14px) * ($step - 1)});
    }
  }
}
.step-box__content {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 1.5em;
  transition: .3s all;
}
</style>
