<template>
  <div class="proof-right text-primary">
    <span class="proof-right__check">✓</span>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.proof-right {
  font-weight: 600;
}
.proof-right__check {
  font-size: 1.3em;
  font-family: "Lucida Grande";
  font-weight: 400;
  margin-right: .1em;
}
</style>
