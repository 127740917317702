<template>
  <div class="toggle-item" :class="classname" @click="open = (! open)">
    <div class="toggle-item__title">
      <slot name="title" />
    </div>
    <div class="toggle-item__desc">
      <slot name="desc" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from '@/utils'

const props = defineProps({
  withBottomBorder: Boolean
})

const open = ref(false)
const classname = computed(() => ({
  'toggle-item--open': open.value,
  'toggle-item--bottom-border': props.withBottomBorder
}))
</script>

<style lang="scss" scoped>
.toggle-item {
  position: relative;
  margin-top: .4em;
  margin-bottom: .4em;
  transform: .3s all;
  &.toggle-item--open.toggle-item--bottom-border {
    border-bottom: 2px solid $color-primary;
  }
  &.toggle-item--open {
    border-top: 2px solid $color-primary;
    padding-top: 1.6em;
    padding-bottom: 1.6em;
    .toggle-item__title {
      font-size: 1.2rem;
      font-weight: 900;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0;
      line-height: 1.6;
      border-left: 4px solid $color-primary;
      background: #fff;
      color: $color-primary;
      &:after {
        transform: rotate(225deg);
        border-right: 2px solid $color-primary;
        border-bottom: 2px solid $color-primary;
        top: 12px;
        right: 20px;
        margin-top: 0;
      }
    }
    .toggle-item__desc {
      display: block;
    }
  }
}
.toggle-item__title {
  position: relative;
  padding: .7em 2.7em .7em 1em;
  border-radius: 15px;
  background: $color-primary;
  color: #fff;
  transition: .3s all;
  font-size: .875rem;
  &:after {
    transition: .3s all;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(45deg);
    display: block;
    content: '';
    @include size(10px);
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }
}
.toggle-item__desc {
  display: none;
  color: #686868;
  line-height: 2;
  margin-top: 1.9em;
}
</style>
