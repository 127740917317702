<template>
  <span class="check-circle" :class="classname" />
</template>

<script setup>
import { computed, defineProps } from '@/utils'

const props = defineProps({
  dark: Boolean,
  xdelivery: Boolean
})

const classname = computed(() => ({
  'check-circle--xdelivery': props.xdelivery,
  'check-circle--dark': props.dark
}))
</script>

<style lang="scss" scoped>
.check-circle {
  display: inline-block;
  position: relative;
  background: #fff;
  border-radius: 50%;
  margin-top: .2em;
  @include size(23px);
  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 2px;
    border-radius: 2px;
    background-color: $color-primary;
  }
  &:before {
    width: 7px;
    transform: translate(3px, 13px) rotate(45deg);
  }
  &:after {
    width: 14px;
    transform: translate(6px, 11px) rotate(-45deg);
  }
}
.check-circle.check-circle--dark {
  background: $color-primary;
  &:before,
  &:after {
    background-color: #fff;
  }
}
.check-circle.check-circle--xdelivery {
  background: $color-xdelivery;
  &:before,
  &:after {
    background-color: #fff;
  }
}
</style>
