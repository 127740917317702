<template>
  <div class="case-study-list"
       :class="classname"
       v-visibility="handleVisibilityChange">

    <img class="case-study-list__logo"
         src="@/assets/landing-logo.svg"
         alt="飛啊! 光速火箭!" />

    <div class="case-study-list__title">
      <h3 class="text-primary">看看我們的成功案例 !</h3>
    </div>
    <case-study-item v-for="c in CASE_STUDY_ITEMS"
                     :key="c.id"
                     :alt="c.alt"
                     :title="c.title"
                     :desc="c.desc"
                     :logo-url="c.logoUrl"
                     :width="c.width"
                     :height="c.height"
                     :to="c.to" />
  </div>
</template>

<script setup>
import CaseStudyItem from '@/components/CaseStudyItem'
import useWindowScroll from '@/composables/useWindowScroll'
import { computed, ref, watch, onBeforeUnmount } from '@/utils'
import { CASE_STUDY_ITEMS } from '@/consts/home'

const shot = ref(false)
const { scrolling, unsubscribe } = useWindowScroll()
const classname = computed(() => ({
  'case-study-list--shot': shot.value
}))
let isIntersecting = false
const handleVisibilityChange = entry => {
  isIntersecting = entry.isIntersecting
  if (! isIntersecting) {
    shot.value = false
  }
}
watch(scrolling, () => {
  if ((! scrolling.value) && isIntersecting) {
    shot.value = true
  }
})
onBeforeUnmount(() => unsubscribe())
</script>

<style lang="scss" scoped>
.case-study-list__title h3,
.case-study-list__logo {
  transition: .3s all;
}
.case-study-list {
  padding-top: 2.3em;
  position: relative;
  background: #fff;
  padding-bottom: 1em;
  h3 {
    text-align: center;
  }
}
.case-study-list.case-study-list--shot {
  .case-study-list__logo {
    transform: translateY(-120px);
    opacity: 0;
  }
  .case-study-list__title h3 {
    transform: translateY(0);
    opacity: 1;
  }
}
.case-study-list__title {
  overflow: hidden;
  margin-bottom: 2em;
  h3 {
    transform: translateY(30px);
    opacity: 0;
  }
}
.case-study-list__logo {
  transform: translateY(0);
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  display: block;
  margin: 0 auto;
  width: 50px;
  height: auto;
}
</style>
