<template>
  <div class="check-heading" :class="classname">
    <check-circle class="check-heading__circle"
                  dark
                  :xdelivery="xdelivery" />
    <slot />
  </div>
</template>

<script setup>
import CheckCircle from '@/components/CheckCircle'
import { computed, defineProps } from '@/utils'

const props = defineProps({
  xdelivery: Boolean
})

const classname = computed(() => ({ 'check-heading--xdelivery': props.xdelivery }))
</script>

<style lang="scss" scoped>
.check-heading {
  display: flex;
  font-weight: 900;
  font-size: 1rem;
  color: $color-primary;
  &.check-heading--xdelivery {
    color: $color-xdelivery;
  }
}
.check-heading__circle {
  transform: scale(.8);
  margin-right: .4em;
}
</style>
