import { fromEvent, firstValue } from '@/utils'

export default function() {
  let autosize
  const map = new Map()
  const bind = el => {
    if (autosize) {
      return autosize(el)
    }
    return import('autosize')
      .then(res => {
        autosize = res.default
        autosize(el)
      })
  }
  const unbind = el => autosize && autosize.destroy(el)
  return {
    mounted(el) {
      const subscription = fromEvent(el, 'click')
        .pipe(firstValue())
        .subscribe(() => bind(el))
      map.set(el, subscription)
    },
    beforeUnmount(el) {
      unbind(el)
      const subscription = map.get(el)
      subscription.unsubscribe()
      map.delete(el)
    }
  }
}
