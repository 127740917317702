<template>
  <div class="xdelivery-form">
    <div class="xdelivery-form__content">
      <h3 v-anchor id="contact-xdelivery" class="text-xdelivery text-center">我要申請</h3>
      <div v-if="created" class="xdelivery-form__success text-xdelivery">您好，感謝您的申請！<br>您的資訊將由一頁商店審核小組進行確認，<br>審核流程約需 3-5 個工作天 (不含例假日)，<br>如通過申請，我們會寄送 e-mail 通知您，<br>如未通過申請，恕不另行通知，<br>有任何問題，歡迎您於上班時間洽詢線上客服，謝謝！</div>
      <div v-else>
        <div class="text-xdelivery">歡迎申請火箭快遞。火箭快遞為「審核制」請您提供下列資訊，審核後我們會立即通知您，並幫您開啟火箭快遞帳號。</div>
        <div>* 號為必填欄位</div>
        <form autocomplete="off" @submit.stop.prevent="submit">

          <form-input v-model="form.company_name"
                      label="公司名稱 (合約用)"
                      placeholder="範例: 光速火箭股份有限公司"
                      required
                      :ref="setRef('company_name')"
                      :err="err.first('company_name')" />

          <form-input v-model="form.company_code"
                      label="統一編號 (開發票用)"
                      placeholder="範例: 65279724"
                      required
                      :ref="setRef('company_code')"
                      :err="err.first('company_code')" />

          <form-input v-model="form.company_address"
                      label="公司地址"
                      placeholder="範例: 高雄市前鎮區成功二路 4 號 4 樓 412 室"
                      required
                      :ref="setRef('company_address')"
                      :err="err.first('company_address')"/>

          <form-input v-model="form.warehouse_address"
                      label="退貨地址 (退貨時要用)"
                      placeholder="範例: 高雄市前鎮區成功二路 4 號 4 樓 412 室"
                      required
                      :ref="setRef('warehouse_address')"
                      :err="err.first('warehouse_address')"/>

          <form-input v-model="form.brand_name"
                      label="品牌名稱"
                      hint="全家超商到貨發簡訊使用，最多 8 個字"
                      placeholder="範例: SHARECO"
                      required
                      :ref="setRef('brand_name')"
                      :err="err.first('brand_name')" />

          <form-input v-model="form.contact_name"
                      label="聯絡人姓名"
                      placeholder="範例: 王小明"
                      required
                      :ref="setRef('contact_name')"
                      :err="err.first('contact_name')" />

          <form-input v-model="form.contact_mobile"
                      label="聯絡人電話"
                      hint="業務單位會聯絡你"
                      placeholder="範例: 0912345678"
                      required
                      :ref="setRef('contact_mobile')"
                      :err="err.first('contact_mobile')" />

          <form-input v-model="form.contact_email"
                      label="註冊信箱"
                      placeholder="範例: example@gamil.com"
                      required
                      :ref="setRef('contact_email')"
                      :err="err.first('contact_email')" />

          <form-select v-model="form.monthly_shippments_level_cd"
                       label="每月寄件數量"
                       required
                       :ref="setRef('monthly_shippments_level_cd')"
                       :err="err.first('monthly_shippments_level_cd')" />

          <div class="xdelivery-form__hint xdelivery-form__hint--before-btn text-xdelivery">請您在送出申請前，確認已填寫所有欄位，以加快後續審核流程，謝謝您！</div>

          <app-btn class="xdelivery-form__btn"
                   arrow
                   xdelivery
                   block
                   :loading="loading">立即送出申請</app-btn>

        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppBtn from '@/components/AppBtn'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import { axios, get, reactive, ref } from '@/utils'
import useValidation from '@/composables/useValidation'
import * as validators from '@/validators/xdelivery'

const created = ref(false)
const loading = ref(false)

const form = reactive({
  company_name: '',
  company_code: '',
  company_address: '',
  warehouse_address: '',
  brand_name: '',
  contact_name: '',
  contact_mobile: '',
  contact_email: '',
  monthly_shippments_level_cd: ''
})

const refs = ref({})
const { validate, err, setErrs, setRef } = useValidation({ form, validators, refs })

const submit = () => {
  const success = validate()
  if (! success) {
    return
  }
  loading.value = true
  axios.head('/csrf')
    .then(res => {
      const csrfToken = res.headers['x-csrf-token']
      const data = {
        authenticity_token: csrfToken,
        apply: form
      }
      axios.post('/applies/xdelivery.json', data)
        .then(() => {
          created.value = true
          if (window.fbq) {
            window.fbq('track', 'CompleteRegistration')
          }
        })
        .catch(err => {
          const fieldErrs = get(err, 'response.data.errors')
          if (fieldErrs) {
            setErrs(fieldErrs)
          }
        })
        .finally(() => loading.value = false)
    })
}
</script>

<style lang="scss">
.xdelivery-form .form-control:focus {
  border-color: $color-xdelivery;
}
</style>

<style lang="scss" scoped>
.xdelivery-form {
  position: relative;
  background: $bg-footer;
}
.xdelivery-form__content {
  padding: 1.6em 1.5em;
  background: #fff;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  h3 {
    margin-bottom: .8em;
  }
}
.xdelivery-form__success {
  margin-bottom: 2em;
}
.xdelivery-form__btn {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  min-height: 42px;
}
.xdelivery-form__hint--before-btn {
  margin-top: 2em;
}
</style>
