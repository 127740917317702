import { extend } from '@/utils'

export const CASE_STUDY_ITEMS = [
  {
    title: '李焰の敗家日記',
    desc: '本來是小資族的「李焰」，2016 年開始在網路上分享自己的購物心得\n嘗試賣賣自己喜歡的包包，短短3年快速崛起，從原本工作離職，全職創業\n現在年營收即將破億，成為 81 年次，超年輕的女 CEO。',
    logoUrl: require('@/assets/murfeeli-logo.svg'),
    width: 84,
    height: 52,
    alt: 'MURFEELI',
    to: '/brand/murfeeli'
  },
  {
    title: 'SHARECO',
    desc: '潮牌藝術家離職創業，起家產品: 香水吊卡，使用臉書、IG 社群經營小眾族群\n一頁商店的高轉換率讓「起步不易，市場小」的劣勢，漸漸由虧轉營，成為年收破億公司\n2019 曾莞婷、2019 頑童瘦子、2020 高爾宣 代言品牌。',
    logoUrl: require('@/assets/shareco-logo.svg'),
    width: 84,
    height: 12,
    alt: 'SHARECO',
    to: '/brand/shareco'
  },
  {
    title: 'KP 女王香氛',
    desc: '台灣原創香氛保養品牌，運用「KOL行銷」打造網紅聯名銷售頁\n發揮粉絲經濟效益，快速衝單、創造爆品，一舉打開市場知名度，登上 VOGUE 雜誌\n在短短三年內，從線上走到線下，於台北、高雄開設百貨實體櫃。',
    logoUrl: require('@/assets/klower-pandor-logo.svg'),
    width: 84,
    height: 34,
    alt: 'KLOWER PANDOR',
    to: '/brand/kplove'
  },
  {
    title: 'H & J',
    desc: '發跡於台灣南部的服飾品牌，轉戰電商選擇了一頁商店\n將實體門市的熱銷商品陸續上架，搭配「藝人代言及 KOL 推薦」衝單\n吸引舊客線上回購，同時開發大量新客戶，短短一年搶下女裝市場 2 億多營業額 ！',
    logoUrl: require('@/assets/huang-jun-logo.svg'),
    width: 84,
    height: 84,
    alt: 'HUANG JUN',
    to: '/brand/huang-jun'
  }
].map((row, i) => extend({}, row, { id: `case-study-item-${i}` }))
