<template>
  <div>
    <div class="img-slide__content">
      <app-img :src="item.src"
               :config="item.imgConfig"
               :alt="item.alt"
               @load="loaded = true" />
      <div v-if="item.quote && loaded" class="img-slide__text">
        <router-link class="img-slide__link" :to="item.to">
          <span class="sr-only">SHARECO</span>
        </router-link>
        <div class="img-slide__quote" v-text="item.quote" />
        <div class="img-slide__author" v-text="item.author" />
      </div>
    </div>
  </div>
</template>

<script setup>
import AppImg from '@/components/AppImg'
import { defineProps, ref } from '@/utils'

defineProps({
  item: {
    type: Object,
    required: true
  }
})

const loaded = ref(false)
</script>

<style lang="scss">
.img-slide__content {
  img {
    border-radius: 20px;
  }
}
</style>

<style lang="scss" scoped>
.img-slide__content {
  position: relative;
}
.img-slide__text {
  position: absolute;
  bottom: 24px;
  padding-left: 1.6em;
  padding-right: 1.6em;
  font-size: 12px;
}
.img-slide__quote {
  line-height: 1.9;
}
.img-slide__author {
  text-align: right;
  line-height: 1;
  font-style: italic;
  &:before {
    content: '';
    width: 3em;
    height: 1px;
    background: #fff;
    display: inline-block;
    transform: translateY(-3px);
    margin-right: .4em;
    opacity: .5;
  }
}
.img-slide__link {
  width: 100%;
  height: 4em;
  display: block;
  margin-bottom: .5em;
}
</style>
