import { isValidEmail } from '@/utils'
import Validator from '@/models/Validator'

export const contact_name = v => {
  return Validator.value(v)
    .required('必須填寫聯絡人')
}

export const contact_mobile = v => {
  return Validator.value(v)
    .required('必須填寫聯絡電話')
}

export const contact_email = v => {
  return Validator.value(v)
    .required('必須填寫聯絡信箱')
    .maxlength(254, '聯絡信箱的長度不可以超過 254 個字元')
    .custom(() => {
      if (! isValidEmail(v)) {
        return '這不是正確的電子郵件格式'
      }
    })
}

export const products_describe = v => {
  return Validator.value(v)
    .required('必須填寫商品說明')
    .maxlength(65535, '商品說明的長度不可以超過 65535 個字元')
}
