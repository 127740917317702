import HomePage from '@/pages/HomePage'
import FeaturePage from '@/pages/FeaturePage'
import CaseStudyPage from '@/pages/CaseStudyPage'
import PricingPage from '@/pages/PricingPage'
import FaqPage from '@/pages/FaqPage'
import BrandPage from '@/pages/BrandPage'
import ContactPage from '@/pages/ContactPage'
import XdeliveryPage from '@/pages/XdeliveryPage'
import NotFoundPage from '@/pages/NotFoundPage'
import { createRouter, createWebHistory } from '@/utils'

const routes = [
  {
    name: 'HomePage',
    path: '/',
    component: HomePage
  },
  {
    name: 'FeaturePage',
    path: '/features',
    component: FeaturePage,
    meta: {
      title: '一頁商店 - 功能介紹'
    }
  },
  {
    name: 'CaseStudyPage',
    path: '/case-studies',
    component: CaseStudyPage,
    meta: {
      title: '一頁商店 - 成功案例'
    }
  },
  {
    name: 'PricingPage',
    path: '/pricing',
    component: PricingPage,
    meta: {
      title: '一頁商店 - 收費方式'
    }
  },
  {
    name: 'FaqPage',
    path: '/faqs',
    component: FaqPage,
    meta: {
      title: '一頁商店 - 常見問題'
    }
  },
  {
    name: 'ContactPage',
    path: '/contact',
    component: ContactPage,
    meta: {
      title: '一頁商店 - 我要申請'
    }
  },
  {
    name: 'XdeliveryPage',
    path: '/xdelivery',
    component: XdeliveryPage,
    meta: {
      title: '一頁商店 - 火箭快遞'
    }
  },
  {
    name: 'BrandPage',
    path: '/brand/:brand',
    component: BrandPage
  },
  {
    name: 'NotFoundPage',
    path: '/not-found',
    component: NotFoundPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPos) {
    const samePage = (to.path === from.path)
    const selector = to.hash
    if (selector) {
      return { selector }
    }
    if (samePage && savedPos) {
      return savedPos
    }
    return { left: 0, top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '一頁商店'
  next()
})

router.afterEach(to => {
  const { gtag, fbq } = window
  gtag('set', 'page_path', to.path)
  gtag('event', 'page_view')
  fbq('track', 'PageView')
})

export default router
