<template>
  <div class="pricing-comparison">
    <h3 class="text-primary">收費方式比較</h3>
    <table class="comparison-table">
      <thead>
        <tr>
          <th>一頁商店</th>
          <th></th>
          <th>其他平台</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>免綁約</td>
          <td>是否綁約</td>
          <td>需綁約</td>
        </tr>
        <tr>
          <td>免繳任何開通費用</td>
          <td>首次開通費</td>
          <td>額外收取一次性開通費</td>
        </tr>
        <tr>
          <td>有收單才需繳費</td>
          <td>最低費用</td>
          <td>繳固定月費 / 年費</td>
        </tr>
        <tr>
          <td class="text-left">費用由金物流平台與店家直接結算，一頁商店不收取任何手續費！</td>
          <td>其他手續費</td>
          <td class="text-left">整合金物流功能，店家使用金物流服務，需額外繳交手續費。</td>
        </tr>
        <tr>
          <td class="text-left">無功能分級制度，所有店家都享有完整系統功能。</td>
          <td>功能分級</td>
          <td class="text-left">根據年費方案的高低，開放不同系統功能，有些進階功能需加購才能使用。</td>
        </tr>
        <tr>
          <td>無限制</td>
          <td>商品數量</td>
          <td class="text-left">部分平台會依據方案，限制商品上架數量。</td>
        </tr>
        <tr>
          <td>無限制</td>
          <td>頁面數量</td>
          <td class="text-left">部分平台會依據方案，限制頁面數量。</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.pricing-comparison {
  background: #fff;
  margin-top: -1px;
  padding-top: 1.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 1em;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  h3 {
    text-align: center;
  }
}
</style>
