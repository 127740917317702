import hexToRgb from './hexToRgb'
import rgbToHex from './rgbToHex'
export default function colorGradient(scale, hex1, hex2) {
  const ratio = scale / 100
  const color1 = hexToRgb(hex1)
  const color2 = hexToRgb(hex2)
  const diffR = color2.r - color1.r
  const diffG = color2.g - color1.g
  const diffB = color2.b - color1.b
  const transform = (v, diff) => parseInt(Math.floor(v + (diff * ratio)), 10)
  const gradient = {
    r: transform(color1.r, diffR),
    g: transform(color1.g, diffG),
    b: transform(color1.b, diffB)
  }
  return rgbToHex(gradient)
}
