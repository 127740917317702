<template>
  <div v-if="item" class="brand-page">
    <app-img class="brand-page__bg"
             :src="item.bgUrl"
             :config="item.bgConfig"
             :alt="item.bgAlt" />
    <div class="brand-page__box">
      <div class="brand-page__label">{{ label }}</div>
      <app-img v-if="item.logoUrl"
               class="brand-page__logo"
               :src="item.logoUrl"
               :alt="item.logoAlt" />
      <h2 v-if="item.logoText" class="brand-page__heading">{{ item.logoText }}</h2>
      <div class="brand-page__desc">{{ item.desc }}</div>
      <app-btn v-if="item.url"
               class="brand-page__btn"
               arrow
               :href="item.url" target="_blank">前往頁面</app-btn>
      <app-img class="brand-page__mobile-demo"
               :src="item.mobileDemoImgUrl"
               :config="item.mobileDemoImgConfig"
               :width="item.mobileDemoWidth"
               :height="item.mobileDemoHeight"
               :alt="item.mobileDemoAlt" />
    </div>
  </div>
  <not-found-page v-else />
</template>

<script setup>
import AppBtn from '@/components/AppBtn'
import AppImg from '@/components/AppImg'
import NotFoundPage from '@/pages/NotFoundPage'
import { useRoute } from '@/utils'
import { BRAND_TYPE_MAP, BRAND_MAP } from '@/consts/brand'

const { brand } = useRoute().params
const item = BRAND_MAP[brand]
const label = item ? BRAND_TYPE_MAP[item.type].text : ''
</script>

<style lang="scss">
.brand-page__bg img {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.brand-page {
  background-color: #000;
  padding-top: 306px;
}
.brand-page__bg {
  position: absolute;
  top: $nav-height;
  left: 0;
  right: 0;
}
.brand-page__box {
  position: relative;
  background: #fff;
  padding: 40px 1.5rem 40px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.brand-page__logo {
  display: block;
  max-width: 160px;
  height: auto;
}
.brand-page__heading {
  color: #000;
  font-size: 1.25rem;
}
.brand-page__label {
  color: $color-primary;
  border-left: 2px solid $color-primary;
  padding-left: .4em;
  margin-bottom: 1em;
  line-height: 1.4;
}
.brand-page__btn {
  margin: 1em 0;
  font-size: .9375rem;
  font-weight: 400;
}
.brand-page__desc {
  margin-top: .6em;
}
.brand-page__mobile-demo {
  width: 260px;
  height: auto;
  margin: 2em auto 0;
  display: block;
}
</style>
