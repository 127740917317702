<template>
  <div class="proof-wrong text-primary">
    <span class="proof-wrong__cross" />
    <slot />
  </div>
</template>

<style lang="scss" scoped>
$cross-size: 14px;
.proof-wrong {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .876rem;
  padding-left: 1em;
}
.proof-wrong__cross {
  display: inline-block;
  margin-right: .5em;
  position: relative;
  @include size($cross-size);
  &:before,
  &:after {
    content: '';
    background: $color-primary;
    position: absolute;
    width: 3px;
    height: $cross-size;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
</style>
