import { onMounted, onBeforeUnmount } from '@/utils'

export default function useAnchor() {
  onMounted(() => {
    document.documentElement.classList.add('scroll-smooth')
  })
  onBeforeUnmount(() => {
    document.documentElement.classList.remove('scroll-smooth')
  })
}
