import { onBeforeUnmount, now, useRoute, watch, ref } from '@/utils'
import { loadIntercom } from '~/official-common/utils'

export default function useIntercom() {

  const APP_ID = 'nmuc7nrk'

  const loading = ref(false)

  const call = (type, ...args) => {
    return window.Intercom && window.Intercom(type, ...args)
  }

  const initIntercom = () => {
    loading.value = true
    loadIntercom(APP_ID)
      .then(() => {
        call('boot', { app_id: APP_ID })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const showIntercom = () => call('show')

  const route = useRoute()
  const onRouteUpdate = () => call('update', { last_request_at: now() })

  watch(() => route.name, onRouteUpdate)
  onBeforeUnmount(() => call('shutdown'))

  return { initIntercom, showIntercom, loading }
}
