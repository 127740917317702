<template>
  <i class="app-icon" aria-hidden="true" :class="classname" />
</template>

<script setup>
import { computed, defineProps } from '@/utils'

const props = defineProps({
  name: {
    type: String,
    required: true
  }
})

const classname = computed(() => `icon-${props.name}`)
</script>

<style lang="scss" scoped>
.app-icon {
  display: inline-block;
}
</style>
