<template>
  <div class="xdelivery-pricing-table">
    <table>
      <tr v-for="row in lvRows" :key="row.text">
        <td class="xdelivery-pricing-table__text-cell">{{ row.text }}</td>
        <td class="xdelivery-pricing-table__count-cell">{{ p(row.count) }} X</td>
        <td class="xdelivery-pricing-table__cost-cell">{{ t(row.cost) }}</td>
        <td class="xdelivery-pricing-table__subtotal-cell">{{ t(row.fee) }}</td>
      </tr>
    </table>
    <div class="xdelivery-pricing-table__total">{{ p(value) }} 包裹，運費為 {{ t(totalFee) }}</div>
  </div>
</template>

<script setup>
import { computed, defineProps, extend, keyBy } from '@/utils'
import { PRICING_LVS, toPiece as p, toTwd as t, getPricingLvs } from '~/official-common/consts/xdelivery'

const props = defineProps({
  value: {
    type: Number,
    required: true
  }
})
const lvRows = computed(() => {
  const lvMap = keyBy(getPricingLvs(props.value), 'lv')
  return PRICING_LVS.map(row => {
    const lv = lvMap[row.lv] || {}
    return extend({ count: 0, fee: 0 }, lv, row)
  })
})
const totalFee = computed(() => {
  return lvRows.value.reduce((sum, row) => sum + row.fee, 0)
})
</script>

<style lang="scss" scoped>
.xdelivery-pricing-table {
  background: linear-gradient(45deg, #d9eeeb, #fff);
  padding: 1em;
  margin-top: 2.4em;
  > table {
    width: 100%;
  }
}
.xdelivery-pricing-table__text-cell {
  font-weight: 500;
}
.xdelivery-pricing-table__count-cell,
.xdelivery-pricing-table__cost-cell,
.xdelivery-pricing-table__subtotal-cell {
  text-align: right;
}
.xdelivery-pricing-table__total {
  font-weight: 500;
  font-size: .9rem;
  text-align: center;
  border-top: 1px solid #bbb;
  padding-top: .5em;
  margin-top: .5em;
}
</style>
