import { isValidEmail, hasValue } from '@/utils'

class Validator {

  constructor(value) {
    this.value = value
    this.errs = []
  }

  static value(value) {
    return new Validator(value)
  }

  required(msg) {
    if (! hasValue(this.value)) {
      this.errs.push({ type: 'required', msg })
    }
    return this
  }

  maxlength(maxlength, msg) {
    const length = String(this.value).length
    if (length >= maxlength) {
      this.errs.push({ type: 'maxlength', msg })
    }
    return this
  }

  email(msg) {
    const { value } = this
    if (hasValue(value) && (! isValidEmail(value))) {
      this.errs.push({ type: 'email', msg })
    }
    return this
  }

  custom(fn) {
    const msg = fn()
    if (hasValue(msg)) {
      this.errs.push({ type: 'custom', msg })
    }
    return this
  }
}

export default Validator
