<template>
  <div>
    {{ content }}
    <a class="read-more__btn"
       @click.stop.prevent="more = (! more)">{{ btnText }}</a>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from '@/utils'

const SHRINK_LENGTH = 33

const props = defineProps({
  text: {
    type: String,
    required: true
  }
})

const more = ref(false)
const content = computed(() => {
  const { text } = props
  if (more.value) {
    return text
  }
  return text.slice(0, SHRINK_LENGTH)
})
const btnText = computed(() => more.value ? '...收回' : '...閱讀更多')
</script>

<style lang="scss" scoped>
.read-more__btn {
  white-space: nowrap;
}
</style>
