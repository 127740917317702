<template>
  <div class="pricing-table">
    <table>
      <tr v-for="row in lvRows" :key="row.text">
        <td class="pricing-table__text-cell">{{ row.text }}</td>
        <td class="pricing-table__income-cell">{{ t(row.value) }} X</td>
        <td class="pricing-table__rate-cell">{{ toPercent(row.rate * 100) }}</td>
        <td class="pricing-table__subtotal-cell">{{ comma(roundX(row.fee)) }} 元</td>
      </tr>
    </table>
    <div class="pricing-table__total">月收單 {{ t(value) }}，系統費為 {{ comma(totalFee) }} 元</div>
  </div>
</template>

<script setup>
import { ceil, comma, computed, defineProps, extend, toPercent, keyBy, roundX } from '@/utils'
import { PRICING_LVS, toTenThousand as t, getPricingLvs } from '~/official-common/consts/pricing'

const props = defineProps({
  value: {
    type: Number,
    required: true
  }
})
const lvRows = computed(() => {
  const lvMap = keyBy(getPricingLvs(props.value), 'lv')
  return PRICING_LVS.map(row => {
    const lv = lvMap[row.lv] || {}
    return extend({ value: 0, fee: 0 }, lv, row)
  })
})
const totalFee = computed(() => {
  const total = lvRows.value.reduce((sum, row) => sum + row.fee, 0)
  return ceil(total)
})
</script>

<style lang="scss" scoped>
.pricing-table {
  background: linear-gradient(-45deg, #f5e5f4, #e6e3f9);
  padding: 1em;
  margin-top: 2.4em;
  > table {
    width: 100%;
  }
}
.pricing-table__text-cell {
  font-weight: 500;
}
.pricing-table__income-cell,
.pricing-table__rate-cell,
.pricing-table__subtotal-cell {
  text-align: right;
}
.pricing-table__total {
  font-weight: 500;
  font-size: .9rem;
  text-align: center;
  border-top: 1px solid #bbb;
  padding-top: .5em;
  margin-top: .5em;
}
</style>
